
.index table {
  border: 1px solid #DDD;
}

.index table th {
  background: #444;
  font-size: 12px;
  padding: 2px 5px 2px 5px;
}

.index table th, #content_wrapper table th a {
  color: #FFF;
  text-align: left;
}

.index table td {
  background: #FFF;
  font-size: 12px;
  padding: 2px 5px 2px 5px;
}

.index table tr:nth-child(odd) td {
  background: #FAFAFA;

}

.pageinfo {
  font-size: 10px;
  color: #AAA;
}

.paging {
  font-size: 10px;
  color: #AAA;
}

.actions {
  margin-top: 5px;
}

.actions a {
  float: left;
  width: auto;
  height: 17px;
  line-height: 17px;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #c4c4c4;
  color: #000;
  margin-right: 5px;
  padding: 0 5px 0 5px;
}

.actions a:hover {
  background: #DDD;
}

.nopadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.overzicht.inplannen td.weekend,
.overzicht.inplannen th.weekend {
  background: repeating-linear-gradient(
    -45deg,
    #f9f9f9,
    #f9f9f9 5px,
    #DADADA 5px,
    #DADADA 10px
  ) !important;
  color: #404040 !important;
}

.overzicht.inplannen .item:not(.not-editable) {
  cursor: pointer;
}

.overzicht.inplannen .item.empty {
  cursor: default;
  height: inherit;
}

.overzicht.inplannen .item.marker {
  background: gray;
  position: absolute !important;
  height: 10px;
  z-index: 0;
  cursor: default;

  -moz-opacity: 0.40;
  opacity: .40;
  filter: alpha(opacity=40);
}

.overzicht.inplannen .item.marker.empty {
  background: none;
}

.overzicht.inplannen tr.marker {
  height: 0px;
}

.overzicht.inplannen tr.marker td {
  height: 0px;
  border: none;
}

.overzicht.inplannen .item.leave {
  background: #979797;
  cursor: default;
}

.overzicht.inplannen .item.selected span {
  /*color: #FFF;*/
  font-style: italic;
}

.overzicht.verlof .special_days td,
.overzicht.inplannen .special_days td {
  border-bottom: 1px solid #000;
}

.overzicht tr td.name div {
  padding-top: 5px;
}

.overzicht.inplannen td.name input {
  margin-right: 10px;
}

.overzicht.dagplanning .time.leave {
  background: #EEE;
}

.bottom_content .overzicht.deelprojecten tr {
  cursor: pointer;
}

#dvLoading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  z-index: 20000;
  display: none;
  text-align: center;
}

#dvLoading .icon-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  background-color: rgba(113, 113, 113, 0.8);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

#dvLoading i {
  line-height: 50px;
  z-index: 101;
  color: #f9f9f9;
  font-size: 22px;
}

/* ********************************************************************************************* */
/* Message */
/* ********************************************************************************************* */
#flashMessage {
  background: rgba(0, 120, 0, 1);
  color: #FFF;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  position: fixed;
  z-index: 99999;
  text-align: center;
  width: 100%;
  height: 35px;
  padding-top: 15px;
  top: 0;
}

#flashMessage.error {
  background: rgba(200, 0, 0, 1);
}

.row span {
  font-size: 12px;
  margin-left: 5px;
}

.short input {
  width: 30px;
}

.deelproject > td > input {
  display: none;
}

.deelproject > td > textarea {
  display: none;
}

.deelproject.edit input {
  display: inline;
}

.deelproject.edit textarea {
  display: inline;
  height: 40px;
  width: 342px;
}

#PlanningRemarks {
  height: 40px;
  width: 300px;
}

.deelproject span {
  display: inline;
}

.deelproject.edit span {
  display: none;
}

.deelproject .input.checkbox.bulk-delete {
  display: inline !important;
}

.deelproject .first .fa-lock {
  position: absolute;
  top: 7px;
  left: 0;
}

.form.login .button {
  float: left;
}

.sub-menu li {
  position: relative;
}

.error-message {
  background: red;
  color: white;
  font-size: 12px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  position: absolute;
  top: 0;
  padding: 0 5px;
  left: 357px;
}

.form-error {
  border: 1px solid red !important;
  padding: 1px 0;
}

.content.edit_project {
}

.form-datetime {
  display: inline;
  float: left;
}

.form-datetime div {
  float: left;
  margin-right: 3px;
}

.form-datetime input {
  margin-right: 3px;
}

.form-datetime .combobox {
  float: left;
  margin-left: 0;
}

.form .form-date {
  float: left;
  background: #f5f5f5;
  width: 60px;
  border: 1px solid #747474;
  height: 23px;
  font-size: 12px;
  color: #434343;
  line-height: 23px;
  padding: 0 5px;
  margin-right: 6px;
}

.form .form-time {
  float: left;
  background: #f5f5f5;
  width: 15px;
  border: 1px solid #747474;
  height: 23px;
  font-size: 12px;
  color: #434343;
  line-height: 23px;
  padding: 0 5px;
}

.inplannen td {
  position: relative;
}

.verlof.overzicht td .specialday,
.inplannen td .specialday {
  text-align: center;
  font-size: 10px;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  text-shadow: 1px 1px #fff;
}

.content input[type=checkbox] {
  width: auto;
}

.content .errors {
  color: red;
  font-size: 13px;
}

.menu_left a.disabled {
  color: #AAA;
}

.content .dropdownfilter {
  position: absolute;
  background: #444;
  top: 25px;
  right: 95px;
  margin: 5px 0 0 0;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  width: 165px;
}

.content .dropdownfilter a {
  color: #FFF;
  text-decoration: none;
}

.content .dropdownfilter a.select {
  background: url(/img/asc_white.jpg) right no-repeat;
  display: inline-block;
  width: 140px;
  height: 22px;
  margin: 0 0 0 10px;
}

.content .dropdownfilter .options {
  position: absolute;
  z-index: 9999;
  height: 70px;
  display: none;
}

.dropdownfilter.projectcategory .options,
.dropdownfilter.employeequalifications .options {
  height: 600px;
  max-height: 600px;
  overflow-y: auto;
}

.content .dropdownfilter .options a {
  float: left;
  background: #666;
  display: inline;
  width: 150px;
  padding-left: 15px;
}

.content .dropdownfilter .options a.selected {
  background: #505050;
}

.content .dropdownfilter .options a.selected::before {
  content: '\f00c';
  font-family: 'FontAwesome';
  position: absolute;
  left: 2px;
  color: #fff;
}

.content .dropdownfilter .options a:hover {
  background: #888;
}

.content .dropdownfilters {
  top: -36px;
  right: 315px;
  display: inline-block;
}

.content .dropdownfilters:nth-of-type(1) {
  right: 485px;
}

.cake-error {
  position: relative;
  z-index: 99999;
  background: red;
  color: white;
  margin-top: 10px;
}

.overzicht.inplannen .grid {
  position: absolute;
  width: 100%;
  height: 100px;
  border-right: 1px solid #BBB;
  top: 31px;
  left: -1px;
}

.overzicht.inplannen td.weekend {
  color: #AAA;
}

.overzicht.inplannen td.hide {
  display: none;
}

#project_nav .plannings {
  display: none;
}

#project_nav li a {
  position: relative;
}

#project_nav li:hover .plannings {
  display: block;

}

#project_nav ul {
  position: relative;
}

#project_nav ul ul {
  position: absolute;
  z-index: 99;
  list-style: none;
  background: #DDD;
}

#project_nav ul ul li {
  padding: 5px;
  overflow: hidden;
}

#project_nav ul ul li:hover {
  background: #C8C8C8;
}

#project_nav ul ul li a {
  color: #000;
}

#project_nav ul ul li .button {
  margin-left: 15px;
}

#title .user {
  float: right;
  font-size: 10px;
  color: #666;
  font-weight: normal
}

.bottom_content.project_inplannen {
  box-shadow: 1px 1px 5px 0 #333;
}

.bottom_content.project_inplannen .info {
  width: 500px;
  float: left;
}

.bottom_content.project_inplannen .options {
  width: 150px;
  float: left;
}

.bottom_content.project_inplannen .stats {
  width: 235px;
  overflow: hidden;
  float: left;
}

.bottom_content.project_inplannen .hours {
  width: 60px;
}

.bottom_content.project_inplannen .form-textarea {
  width: 343px;
}

.bottom_content.project_inplannen .stats .panel {
  color: #7A7A7A;
  float: left;
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  clear: both;
  margin-bottom: 5px;
  padding: 5px;
  width: 220px;
  height: 40px;
}

.bottom_content.project_inplannen .stats .panel h1 {
  font-size: 12px;
  color: #7A7A7A;
}

.bottom_content.project_inplannen .stats .panel h1 span {
  font-size: 10px;
  font-weight: normal;
  margin-left: 5px;
}

.bottom_content.project_inplannen .stats .panel .progress_bar {
  float: left;
  width: 148px;
  height: 13px;
  margin: 5px 0;
  border: 1px solid #737373;
}

.bottom_content.project_inplannen .stats .panel .hours {
  float: left;
  margin: 5px 0 0 5px;
  font-size: 10px;
  font-weight: normal;
}

.bottom_content.project_inplannen .stats .panel .progress_bar .bar {
  position: relative;
  height: 13px;
}

.bottom_content.project_inplannen .stats .panel .pattern {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*background: url(/img/patterns/pattern.png) repeat;*/
}

.deelproject .week {
  width: 20px;
}

.deelproject .year {
  width: 30px;
}

/* 10-04-2013 */
#content_wrapper .leftcolumn {
  float: left;
  width: 80%;
}

#content_wrapper .rightcolumn {
  float: left;
  width: 20%;
}

#content_wrapper .rightcolumn.fixed {
  position: fixed;
  right: 0;
  top: 65px;
}

.overzicht.dagplanning .teammembers,
.overzicht.dagplanning .machines {
  padding: 0;
  vertical-align: top;
}

.overzicht.dagplanning .teammembers li,
.overzicht.dagplanning .machines li {
  list-style: none;
  border-bottom: 1px solid #cecece;
}

.overzicht.dagplanning .teammembers li .fa-remove,
.overzicht.dagplanning .machines li .fa-remove {
  cursor: pointer;
}

.overzicht.dagplanning .teammembers li.ok,
.overzicht.dagplanning .machines li.ok {
  border-bottom: none;
  background: #0A0;
  color: #FFF;
}

.overzicht.dagplanning .teammembers li.incomplete {
  border-bottom: none;
  background: #A00;
  color: #FFF;
}

.overzicht.dagplanning .teammembers li.teamleader,
.overzicht.dagplanning .machines .ajaxmachines li {
  background: #DDD;
}

.overzicht.dagplanning .teammembers li span,
.overzicht.dagplanning .machines li span {
  color: #AAA;
}

.overzicht.dagplanning .teammembers.ui-state-highlight,
.overzicht.dagplanning .machines.ui-state-highlight {
  background: #999;
}

#employeesearch {
  margin-top: 20px;
}

.employees .employeetitle {
  font-size: 12px;
  margin-top: 7px;
  margin-bottom: 8px;
}

#employeesearch {

}

.rightcolumn {
  height: calc(100vh - 175px);
  overflow-y: auto;
  background-color: #FFF;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  margin-top: -27px;
}

.rightcolumn .employees {
  padding: 10px;
  min-height: 200px;
}

.rightcolumn .employees span {
  font-size: 12px;
}

.rightcolumn .employees .title {
  font-size: 12px;
  margin: 7px 0 8px 0;
}

.rightcolumn #machinelist,
.rightcolumn .employeelist {
  list-style: none;
}

.rightcolumn #machinelist li,
.rightcolumn .employeelist li {
  font-size: 12px;
  color: #404040;
  line-height: 24px;
  border-top: 1px solid #cecece;
  list-style-type: none;
}

.rightcolumn .employeelist li div.employee-droppable {
  background: #ffffff;
  display: inline-block;
  z-index: 999;
  cursor: move;
}

.rightcolumn #machinelist li {
  display: block;
}

.rightcolumn .employeelist li div.ui-draggable-dragging,
li.machine-droppable.ui-draggable-dragging {
  background: #000;
  color: #FFF;
  padding: 0 5px 0 5px;
  z-index: 999999;
  font-size: 12px;
  list-style-type: none;
}

.rightcolumn #employeelist li .hours {
  color: #AAA;
}

.teammembers .ajaxteam {
  position: relative;
}

.overlay {
  display: none;
  width: 100%;
  height: 100% !important;
  position: absolute;
  top: 0;
  height: 0;

  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, .3);
  *background: transparent;
  *filter: progid: DXImageTransform . Microsoft . gradient(startColorStr = #50FFFFFF, endColorStr = #50FFFFFF);
  *-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#50FFFFFF,endColorstr=#50FFFFFF)";
}

.ui-draggable {
  z-index: 9999;
}

#ajaxemployees,
#ajaxmachines {
  overflow-y: auto;
}

.ajaxmachines ul {
  list-style: none;
}

.spinner {
  position: absolute;
  right: 5px;
  top: 5px;
}

.content form#search {
  /*height: 56px;*/
  /*margin: 10px 0;*/
  margin-top: 17px;
}

.projects-index form#search {
  margin-top: 34px;
}

#remove_planning_area {
  display: none;
  /*background: url(/img/patterns/pattern.png) repeat;*/
  height: 45px;
  text-align: center;
  border: 3px dashed #333;
  position: fixed;
  top: 0;
  left: 220px;
  z-index: 99999;
}

#remove_planning_area i {
  margin-top: 15px;
  font-size: 20px;
  color: #C84A4B;
}

#remove_planning_area span {
  margin-top: 15px;
  font-size: 16px;
  color: #C84A4B;
  font-weight: bold;
}

