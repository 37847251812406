#toggleMenu {
    position: absolute;
    top: 7px;
    left: 220px;
    line-height: 18px;
    background: rgba(0, 0, 0, 0.4);
    padding: 9px 15px 9px 9px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-transition:all 0.5s ease-in-out;
    -moz-transition:all 0.5s ease-in-out;
    -o-transition:all 0.5s ease-in-out;
    -ms-transition:all 0.5s ease-in-out;
    transition:all 0.5s ease-in-out;
    -webkit-box-shadow: 4px 0px 25px 0px rgba(51,51,51,.3);
    -moz-box-shadow: 4px 0px 25px 0px rgba(51,51,51,.3);
    box-shadow: 4px 0px 25px 0px rgba(51,51,51,.3);
}

#toggleMenu i {
    color: #f9f9f9;
}

#toggleMenu:hover {
    cursor: pointer;
}

#menu_left_wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 220px;
    /*padding: 0 5px 0 0;*/
    -webkit-box-shadow: 4px 0px 25px 0px rgba(51,51,51,.3);
    -moz-box-shadow: 4px 0px 25px 0px rgba(51,51,51,.3);
    box-shadow: 4px 0px 25px 0px rgba(51,51,51,.3);
    background: #fff;
    z-index: 9999;
    -webkit-transition:all 0.5s ease-in-out;
    -moz-transition:all 0.5s ease-in-out;
    -o-transition:all 0.5s ease-in-out;
    -ms-transition:all 0.5s ease-in-out;
    transition:all 0.5s ease-in-out;
}

#menu_left_wrapper .logo {
    width: 220px;
    background: #fff;
    height: 50px;
    line-height: 46px;
    float: left;
    text-align: center;
}

.logo img {
    margin: auto;
    vertical-align: middle;
    display: inline-block;
}

/* Reset */
.menu_left,
.menu_left ul,
.menu_left li,
.menu_left a,
.menu_left span{
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

.menu_left {
    position: relative;
    height: -webkit-calc(100% - 120px);
    height: -moz-calc(100% - 120px);
    height: calc(100% - 120px);
    padding: 50px 0 39px 0;
    min-height: 700px;
}

.menu_left li {
    list-style: none;
}

.menu_left > li {
    position: relative;
    font-size: 14px;
}

.menu_left > li:hover > a {
    background: #73A024;
    color: #fff;
}

.menu_left > li:hover > a i {
    color: #fff;
}

.menu_left > li.active {
    background: rgb(83,165,53);
    background: linear-gradient(90deg, rgba(83,165,53,1) 0%, rgba(148,184,39,1) 75%, rgba(196,198,28,1) 100%);
    color: #fff;
}

.menu_left > li.active > a {
    color: #fff;
}

.menu_left > .sub-menu > li.active a {
    color: #848485;
}

.menu_left > li.selected {
    background: #73A024;
    color: #fff;
}

.menu_left li i {
    position: absolute;
    left: 20px;
    top: 12px;
    color: #848485;
    padding-right: 20px;
}

.menu_left li.active i {
    color: #f9f9f9;
}

.menu_left li a {
    display: block;
    padding: 10px 0 10px 40px;
    color: #848485;
    text-decoration: none;
}

.menu_left li a.active,
.menu_left li a.active i {
    color: #fff;
}

.menu_left > li > a:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 24px;
    height: 24px;
    margin: 4px 8px;
}

.menu_left > li > a .arrow{
    position: absolute;
    top: 0;
    right: 15px;
    width: 25px;
    height: 37px;
}

/* Sub Menu */
.menu_left li > .sub-menu {
    display: none;
}

.menu_left li:target > .sub-menu {
    display: block;
}

.sub-menu li {
    font-size: 14px;
    background: #fff;
}

.sub-menu li .notify,
.menu_left > li > a > .notify {
    position: absolute;
    top: 6px;
    right: 5px;
    display: block;
    text-align: center;
    width: 15px;
    height: 15px;
    line-height: 15px;
    color: #FFF;
    font-size: 10px;
    background: #E24141;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    border: 2px solid #fff;
}

.menu_left > li > a > .notify {
    top: 8px;
    right: 25px;
    z-index: 10;
}

.sub-menu li a {
    color: #848485;
    padding: 10px 0 10px 50px;
}

.sub-menu li.active a,
.sub-menu li:hover a {
    color: #73A024;
}

.sub-menu li:last-child a { border: none; }

.sub-menu li > a span {
    color: #797979;
    border: 1px solid #c9c9c9;
}

.emendis-logo {
    position: absolute;
    left: 38px;
    bottom: 0;
}

/**
 * PA van Rooyen Theming
 */
body.pavanrooyen #menu_left_wrapper {
    background: #ffffff;
}

body.pavanrooyen .menu_left > li:hover > a {
    background: #00999C;
    color: white;
}

body.pavanrooyen .menu_left > li.active {
    background: #00999C;
}

body.pavanrooyen .menu_left > li li a {
    color: #797979;
}

body.pavanrooyen .menu_left > li.selected {
    background: #00999C;
    color: #F9F9F9;
}

body.pavanrooyen .sub-menu li {
    background: #F9F9F9;
}

body.pavanrooyen .sub-menu li.active a,
body.pavanrooyen .sub-menu li:hover a {
    color: #008A8D;
}

/**
 * Coverplan
 */
body.coverplan #menu_left_wrapper {
    background: #ffffff;
}

body.coverplan .menu_left > li:hover > a {
    background: #666666;
    color: white;
}

body.coverplan .menu_left > li.active {
    background: #666666;
}

body.coverplan .menu_left > li li a {
    color: #797979;
}

body.coverplan .menu_left > li.selected {
    background: #666666;
    color: #F9F9F9;
}

body.coverplan .sub-menu li {
    background: #F9F9F9;
}

body.coverplan .sub-menu li.active a,
body.coverplan .sub-menu li:hover a {
    color: #666666;
}

/**
 * Van Leeuwen Theming
 */
body.vanleeuwen #menu_left_wrapper {
    background: #ffffff;
}

body.vanleeuwen .menu_left > li:hover > a {
    background: #155895;
    color: white;
}

body.vanleeuwen .menu_left > li.active {
    background: #155895;
}

body.vanleeuwen .menu_left > li li a {
    color: #797979;
}

body.vanleeuwen .menu_left > li.selected {
    background: #155895;
    color: #F9F9F9;
}

body.vanleeuwen .sub-menu li {
    background: #F9F9F9;
}

body.vanleeuwen .sub-menu li.active a,
body.vanleeuwen .sub-menu li:hover a {
    color: #155895;
}

/**
 * PP Group Theming
 */
body.ppgroup #menu_left_wrapper {
    background: #ffffff;
}

body.ppgroup .menu_left > li:hover > a {
    background: #00A8DE;
    color: white;
}

body.ppgroup .menu_left > li.active {
    background: #00A8DE;
}

body.ppgroup .menu_left > li li a {
    color: #797979;
}

body.ppgroup .menu_left > li.selected {
    background: #00A8DE;
    color: #F9F9F9;
}

body.ppgroup .sub-menu li {
    background: #F9F9F9;
}

body.ppgroup .sub-menu li.active a,
body.ppgroup .sub-menu li:hover a {
    color: #00A8DE;
}


/**
 * Total Creation Theming
 */
body.total-creation #menu_left_wrapper {
    background: #ffffff;
}

body.total-creation .menu_left > li:hover > a {
    background: #CCD724;
    color: white;
}

body.total-creation .menu_left > li.active {
    background: #CCD724;
}

body.total-creation .menu_left > li li a {
    color: #797979;
}

body.total-creation .menu_left > li.selected {
    background: #CCD724;
    color: #F9F9F9;
}

body.total-creation .sub-menu li {
    background: #F9F9F9;
}

body.total-creation .sub-menu li.active a,
body.total-creation .sub-menu li:hover a {
    color: #CCD724;
}

/**
 * Campina Theming
 */
body.campina #menu_left_wrapper {
    background: #ffffff;
}

body.campina .menu_left > li:hover > a {
    background: #0093DF;
    color: white;
}

body.campina .menu_left > li.active {
    background: #0093DF;
}

body.campina .menu_left > li li a {
    color: #797979;
}

body.campina .menu_left > li.selected {
    background: #0093DF;
    color: #797979;
}

body.campina .sub-menu li {
    background: #F9F9F9;
}

body.campina .sub-menu li.active a,
body.campina .sub-menu li:hover a {
    color: #0093DF;
}


/**
 * Tuinonderhoud Emmen
 */
body.tuinonderhoud-emmen #menu_left_wrapper {
    background: #ffffff;
}

body.tuinonderhoud-emmen .menu_left > li:hover > a {
    background: #186C2C;
    color: white;
}

body.tuinonderhoud-emmen .menu_left > li.active {
    background: #186C2C;
}

body.tuinonderhoud-emmen .menu_left > li li a {
    color: #797979;
}

body.tuinonderhoud-emmen .menu_left > li.selected {
    background: #186C2C;
    color: #797979;
}

body.tuinonderhoud-emmen .sub-menu li {
    background: #F9F9F9;
}

body.tuinonderhoud-emmen .sub-menu li.active a,
body.tuinonderhoud-emmen .sub-menu li:hover a {
    color: #186C2C;
}
