body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #f9f9f9;
  font-family: myriad-pro, "Segoe UI", arial;
  /*min-width: 1280px;*/
}

input,
textarea {
  font-family: myriad-pro, "Segoe UI", arial;
  border: 1px solid #333;
}

* {
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
}

img {
  border: 0;
}

.hidden {
  display: none;
}

/* ********************************************************************************************* */
/* Header */
/* ********************************************************************************************* */

#header {
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  background: #fff;
  border-bottom: 2px solid #dadada;
  z-index: 999;
}

#header.presentation {
  z-index: 99999;
}

#header .title {
  font-size: 24px;
  line-height: 20px;
  font-weight: 600;
  margin: 12px 0 0 20px;
  font-family: myriad-pro, "Segoe UI", arial;
  float: left;
}

.header_left,
.header_right {
  float: left;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  color: #000;
  padding: 0 10px;
}

.header_left {
  float: left;
}

.env {
  width: 200px;
  background: #e43;
  position: absolute;
  top: 3px;
  left: -80px;
  text-align: center;
  line-height: 30px;
  font-size: 11px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.env:after {
}

.env .label {
}

.header_right {
  float: right;
  background: #f4f4f4;
  border-left: 1px solid #ababab;
}

.header_right a {
  color: #000;
  text-decoration: none;
}

.header_right i {
  font-size: 20px;
}

/* ********************************************************************************************* */
/* Content */
/* ********************************************************************************************* */

#content_wrapper {
  overflow-x: hidden;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  min-height: 100vh;
}

#content_wrapper:not(.ajax) {
  padding: 0 0 0 220px;
}

#content_wrapper.inplannen {
  padding: 50px 0 292px 220px;
}

#content_wrapper .shadow {
  display: block;
  width: 100%;
  height: 5px;
}

#page_title {
  padding: 30px 30px 22px 50px;
  font-size: 24px;
  line-height: 20px;
  font-weight: 600;
  background: transparent;
}

#page_title > i:not(.sub-title) {
  float: left;
  height: 20px;
  position: fixed;
  top: 14px;
  z-index: 999;
}

#page_title .title:not(.sub-title) {
  float: left;
  margin: 0 0 0 35px;
  position: fixed;
  top: 14px;
  z-index: 999;
}

.content {
  position: relative;
  background: #fff;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  padding: 10px;
}

.content.edit_hour .form > div {
  clear: both;
  min-height: 30px;
}

.content.edit_hour .row-machines {
  height: 75px;
}

.projects-index #project_nav {
  height: 23px;
  overflow: hidden;
}

#project_nav > ul,
#employee_colors_nav > ul {
  padding: 0 18px;
  background: #dddddd;
  list-style: none;
}

#project_nav > ul {
  min-height: 50px;
}

#project_nav > ul > li,
#employee_colors_nav > ul > li {
  position: relative;
  float: left;
  line-height: 25px;
  font-size: 12px;
}

#employee_colors_nav > ul > li {
  padding: 0 10px;
}

#project_nav > ul > li .notify {
  position: absolute;
  top: -15px;
  right: -5px;
  display: block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 10px;
  color: #FFF;
  text-align: center;
  z-index: 9;
  background: #E24141;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  /*border: 2px solid #fff;-webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);*/
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
}

#project_nav.readonly > ul > li > a {
  cursor: default;
}

#project_nav > ul > li > a,
#employee_colors_nav > ul > li > a,
#project_nav > ul > li > div,
#employee_colors_nav > ul > li > div {
  display: block;
  padding: 0 12px;
  text-decoration: none;
  color: #000;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #b4b4b4;
}

#project_nav > ul > li > div,
#employee_colors_nav > ul > li > div {
  cursor: default;
}

#project_nav > ul > li:hover > a {
  background: #cfcfcf;
}

#project_nav.readonly > ul > li:hover > a {
  background: #dddddd;
}

#project_nav > ul > li.first a,
#employee_colors_nav > ul > li.first a,
#project_nav > ul > li.first div,
#employee_colors_nav > ul > li.first div {
  border-left: none;
}

#project_nav > ul > li.last a,
#employee_colors_nav > ul > li.last a,
#project_nav > ul > li.last div,
#employee_colors_nav > ul > li.last div {
  border-right: none;
}

#project_nav > ul > li > a span,
#employee_colors_nav > ul > li > a span,
#project_nav > ul > li > div span,
#employee_colors_nav > ul > li > div span {
  margin: 0 6px 0 0;
  width: 10px;
  height: 10px;
  display: inline-block;
}

#project_nav img {
  border: 0;
}

#project_nav ul li:hover ul {
  display: block;
}

#project_nav ul li ul {
  display: none;
  position: absolute;
  top: 25px;
  left: 0;
  z-index: 9999;
  width: 280px;
  list-style: none;
}

#project_nav ul li ul li {
  background: #DDD;
  border-top: 1px solid #c6c6c6;
  font-size: 12px;
  line-height: 14px;
  padding: 8px 0;
}

#project_nav ul li ul li .name {
  padding: 0 10px;
  width: 171px;
}

#project_nav ul li ul li a {
  display: inline-block;
  height: 17px;
  width: 70px;
  line-height: 17px;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #b1b1b1;
  color: #000;
  padding: 0 5px 0 5px;
  margin: 0 10px 0 0;
}

#project_nav ul li ul li a:hover {
  background: #959595;
}

/* ********************************************************************************************* */
/* Search */
/* ********************************************************************************************* */

#search,
#employeesearch {
  position: relative;
}

#field_search,
#field_search_empl {
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  border: 1px solid #929292;
  width: 100%;
  color: #666666;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#field_search:focus,
#field_search_empl:focus {
  outline: none;
}

#field_submit,
#field_submit_empl {
  position: absolute;
  top: 1px;
  right: 1px;
  height: 26px;
  width: 60px;
  font-family: 'FontAwesome';
  background: #e9e9e9;
  border: 0;
  cursor: pointer;
  text-align: center;
}

#field_submit:hover,
#field_submit_empl:hover {
  background: #717171;
  color: #e9e9e9;
}

.filtered {
  position: absolute;
  top: 25px;
  right: 263px;
  background: #81B32B;
  margin: 5px 0 0 0;
  font-size: 12px;
  padding: 0 0 0 10px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  width: 133px;
}

.filtered.client-side {
  top: -2px;
  right: 75px;
}

.filtered.client-side.ajax_week.qualifications-module {
  right: 400px;
}

.week-planning .filtered {
  top: 5px;
}

.filtered a {
  position: absolute;
  top: 0;
  right: 0;
  height: 22px;
  line-height: 22px;
  text-decoration: none;
  color: #fff;
  background: #73A024;
  padding: 0 7px 0;
  margin: 0 0 0 5px;
}

.noresults {
  background: #AAA;
  font-size: 12px;
  padding: 0 0 0 30px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  display: block;
}

.noresults a {
  position: absolute;
  top: 0;
  right: 0;
  height: 22px;
  line-height: 22px;
  text-decoration: none;
  color: #fff;
  background: #9b1c1c;
  padding: 0 7px 0;
  margin: 0 0 0 5px;
}

/* ********************************************************************************************* */
/* Overzichten */
/* ********************************************************************************************* */

div.leave {
  cursor: pointer !important;
}

.team {
  padding-left: 15px !important;
}

.overzicht {
  position: relative;
  background: #fff;
  margin: -6px 0 0 0;
}

.verlof.overzicht td.last {
  padding-left: 5px;
  padding-right: 5px;
  line-height: 24px;
}

.overzicht.conflicts {
  margin-top: -35px;
}

.overzicht .shadow {
  position: absolute;
  top: 30px;
  left: 0;
}

.overzicht table {
  width: 100%;
  font-size: 12px;
  table-layout: fixed;
}

.fixed-table-header {
  background-color: #f9f9f9;
  position: fixed;
  top: 50px;
  padding-left: 222px;
  right: 0;
  width: 100%;
  display: none;
  z-index: 1;
}

.fixed-table-header th,
.fixed-table-header td {
  color: #333 !important;
  font-size: 11px;
}

.overzicht tr.odd {
}

.overzicht tr.even {
}

.overzicht tr.title {
  height: 30px;
}

.overzicht td {
  position: relative;
  border-bottom: 1px solid #000;
}

.overzicht tr.title td a {
  color: #404040;
  text-decoration: none;
}

.overzicht tr td a {
  color: #848484;
}

.overzicht td.odd,
.overzicht td.even {
  border-right: 1px solid #000;
  height: 50px;
  min-width: 124px;
}

.overzicht.inplannen td.odd,
.overzicht.inplannen td.even {
  vertical-align: top;
}

.overzicht tr.odd td.even,
.overzicht tr.even td.odd,
.overzicht tr.even td.name,
.overzicht tr.even td.first,
.overzicht tr.odd th.even,
.overzicht tr.even th.odd,
.overzicht tr.even th.name,
.overzicht tr.even th.first {
  border-right: 1px solid #000;
  height: 50px;
  font-size: 12px;
  min-width: 124px;
}

.overzicht td.contract-ended,
.overzicht td.contract-not-started {
  background: #acacac;
}

.overzicht tr.title td,
.overzicht tr.title th {
  border-bottom: 1px solid #cecece;
  color: #404040;
  height: 30px;
  padding: 0 0 0 5px;
  font-weight: normal;
}

.overzicht tr.title a.asc {
  padding: 0 15px 0 0;
}

.overzicht tr.title a.asc::before {
  font-family: 'FontAwesome';
  content: '\f0d7';
  margin-right: 5px;
}

.overzicht tr.title a.desc {
  padding: 0 15px 0 0;
}

.overzicht tr.title a.desc::before {
  font-family: 'FontAwesome';
  content: '\f0d8';
  margin-right: 5px;
}

.overzicht tr.top_title td {
  color: #404040;
  height: 15px;
  padding: 0 0 0 5px;
  border: 0;
}

.overzicht tr.top_title td.name {
  border: 0;
  color: #404040;
  height: 15px;
  padding: 0 0 0 5px;
}

.overzicht td.name {
  border-right: 1px solid #000;
  border-left: 0;
  height: 30px;
  width: 200px;
  min-width: 70px;
  color: #848484;
  padding: 0 0 0 30px;
}

.overzicht td.name i {
  width: 17px;
}

.overzicht tr.title td.name {
  padding-left: 0;
  border-right: 0;
}

.overzicht td.first,
.overzicht th .first {
  padding: 0;
  width: 25px;
  font-size: 12px;
  min-width: 5px;
  border-right: 0 !important;
}

.overzicht tr.title td.last {
  border-right: none;
}

.overzicht td.last,
.overzicht th.last {
  padding: 0;
  height: 50px;
  width: 25px;
  min-width: 25px;
  border-right: none !important;
  border-bottom: 1px solid #000;
}

.overzicht td.perc {
  font-size: 10px;
  color: #666;
  text-align: center;
  vertical-align: middle !important;
}

.grey,
.grey_row .item,
.gray,
.gray_row .item {
  background-color: #9A9A9A !important;
  /* Old browsers */
}

.brown,
.brown_row .item {
  background-color: #A07837 !important;
  /* Old browsers */
}

.purple,
.purple_row .item {
  background: #B965BC;
  /* Old browsers */
}

.light_green,
.light_green_row .item {
  background-color: #79BD65 !important;
  /* Old browsers */
}

.green,
.green_row .item {
  background-color: #3e9c41 !important;
  /* Old browsers */
}

.green.gradient {
  background: #7bd75e;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzdiZDc1ZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2M2JlNDgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #7bd75e 0%, #63be48 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #7bd75e), color-stop(100%, #63be48));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #7bd75e 0%, #63be48 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #7bd75e 0%, #63be48 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #7bd75e 0%, #63be48 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #7bd75e 0%, #63be48 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7bd75e', endColorstr='#63be48', GradientType=0);
  /* IE6-8 */
}

.red,
.red_row .item {
  background-color: #d34f50 !important;
  /* Old browsers */
}

.red.gradient {
  background-color: #d34f50 !important;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2QzNGY1MCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlMDA3MWUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #d34f50 0%, #e0071e 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d34f50), color-stop(100%, #e0071e));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #d34f50 0%, #e0071e 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #d34f50 0%, #e0071e 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #d34f50 0%, #e0071e 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #d34f50 0%, #e0071e 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d34f50', endColorstr='#e0071e', GradientType=0);
  /* IE6-8 */
}

.orange,
.orange_row .item {
  background-color: #f38c47 !important;
  /* Old browsers */
}

.pink,
.pink_row .item {
  background-color: #FEA3B2 !important;
  /* Old browsers */
}

.orange.gradient {
  background: #f38c47;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YzOGM0NyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZTZhMDciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #f38c47 0%, #fe6a07 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f38c47), color-stop(100%, #fe6a07));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f38c47 0%, #fe6a07 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f38c47 0%, #fe6a07 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f38c47 0%, #fe6a07 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #f38c47 0%, #fe6a07 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f38c47', endColorstr='#fe6a07', GradientType=0);
  /* IE6-8 */
}

.yellow,
.yellow_row .item {
  background-color: #FAD165 !important;
  /* Old browsers */
}

.black {
  background-color: #858585 !important;
  /* Old browsers */
}

.blue {
  background-color: #6fa5cc !important;
}

.light_blue {
  background-color: #62d7e3 !important;
}

.black {
  background-color: black !important;
}

.aquamarine {
  background-color: aquamarine !important;
}

.crimson {
  background-color: crimson !important;
}

.darkorange {
  background-color: darkorange !important;
}

.greenyellow {
  background-color: greenyellow !important;
}

.lightcoral {
  background-color: lightcoral !important;
}

.lightseagreen {
  background-color: lightseagreen !important;
}

.orangered {
  background-color: orangered !important;
}

.beige {
  background-color: beige !important;
}

.azure {
  background-color: azure !important;
}

.coral {
  background-color: coral !important;
}

.darkblue {
  background-color: darkblue !important;
}

.darkgreen {
  background-color: darkgreen !important;
}

.fuchsia {
  background-color: fuchsia !important;
}

.gold {
  background-color: gold !important;
}

.ivory {
  background-color: ivory !important;
}

.lime {
  background-color: lime !important;
}

.lightsteelblue {
  background-color: lightsteelblue !important;
}

.navy {
  background-color: navy !important;
}

.peachpuff {
  background-color: peachpuff !important;
}


.nocolor,
.nocolor_row .item {
  background-color: #9A9A9A !important;
  /* Old browsers */
}

.no_content {
  border-top: 1px solid #cecece;
  padding: 5px;
}

.content_shadow_top {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 5px;
}

.content .column {
  float: left;
  width: 50%;
  min-width: 370px;
  max-width: 700px;
}

.content .column input[type=number],
.content .column .form-input,
.content .column .form-text,
.content .column .form-textarea {
  width: 230px;
}

.content .column textarea {
  height: 46px;
}

.content .column select {
  width: 242px;
}

.over-apply {
  color: #666;
  position: absolute;
  top: 0;
  left: 12px;
  cursor: text;
  font-size: 13px;
  height: 28px;
  line-height: 28px;
}

.standby {
  color: #ddd !important;
}

.column .form .form-textarea {
  float: left;
  background: #f5f5f5;
  border: 1px solid #747474;
  width: 400px;
  height: 100px;
  font-size: 12px;
  color: #434343;
  line-height: 23px;
  padding: 0 5px;
  resize: vertical;
}

.combobox {
  float: left;
}

.dot {
  float: left;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  padding: 0;
  margin: 0 5px;
}

.special {
  width: 300px;
  padding: 30px;
  margin: 20px 0 100px 0;
  border: 2px solid #b5b5b5;
}

.special li {
  color: #4e4e4e;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 15px;
}

.special a {
  display: block;
  color: #4e4e4e;
  line-height: 24px;
}

.special a:hover {
  text-decoration: none;
}

/* ********************************************************************************************* */
/* Project nav */
/* ********************************************************************************************* */

#project_nav,
#employee_colors_nav {
  position: relative;
  width: 100%;
  border-top: 1px solid #cecece;
  z-index: 99;
}

#project_nav > ul,
#employee_colors_nav > ul {
  padding: 0 18px;
  background: #dddddd;
  list-style: none;
}

#project_nav > ul > li,
#employee_colors_nav > ul > li {
  position: relative;
  float: left;
  line-height: 25px;
  font-size: 12px;
}

#project_nav > ul > li .notify {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 10px;
  color: #FFF;
  text-align: center;
  z-index: 9;
  background: #E24141;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  /*border: 2px solid #fff;-webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);*/
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
}

#project_nav.readonly > ul > li > a {
  cursor: default;
}

#project_nav > ul > li > a,
#project_nav > ul > li > div {
  display: block;
  padding: 0 12px;
  text-decoration: none;
  color: #000;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #b4b4b4;
}

#project_nav > ul > li > div {
  cursor: default;
}

#project_nav > ul > li:hover > a {
  background: #cfcfcf;
}

#project_nav.readonly > ul > li:hover > a {
  background: #dddddd;
}

#project_nav > ul > li.first a,
#project_nav > ul > li.first div {
  border-left: none;
}

#project_nav > ul > li.last a,
#project_nav > ul > li.last div {
  border-right: none;
}

#project_nav > ul > li > a span,
#employee_colors_nav > ul > li > span,
#project_nav > ul > li > div span,
#employee_colors_nav > ul > li > div span {
  margin: 0 6px 0 0;
  width: 10px;
  height: 10px;
  display: inline-block;
}

#project_nav img {
  border: 0;
}

#project_nav ul li:hover ul {
  display: block;
}

#project_nav ul li ul {
  display: none;
  position: absolute;
  top: 25px;
  left: 0;
  z-index: 9999;
  width: 280px;
  list-style: none;
  max-height: 500px;
  overflow-y: auto;
}

#project_nav ul li ul li {
  background: #DDD;
  border-top: 1px solid #c6c6c6;
  font-size: 12px;
  line-height: 14px;
  padding: 8px 0;
}

#project_nav ul li ul li .name {
  padding: 0 10px;
  width: 171px;
}

#project_nav ul li ul li a {
  display: inline-block;
  height: 17px;
  width: 70px;
  line-height: 17px;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #b1b1b1;
  color: #000;
  padding: 0 5px 0 5px;
  margin: 0 10px 0 0;
}

#project_nav ul li ul li a:hover {
  background: #959595;
}

/* ********************************************************************************************* */
/* Search */
/* ********************************************************************************************* */

#search,
#employeesearch {
  position: relative;
}

#field_search,
#field_search_empl {
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  border: 1px solid #929292;
  width: 100%;
  color: #666666;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#field_search:focus,
#field_search_empl:focus {
  outline: none;
}

#field_submit,
#field_submit_empl {
  position: absolute;
  top: 1px;
  right: 1px;
  height: 26px;
  width: 60px;
  font-family: 'FontAwesome';
  background: #e9e9e9;
  border: 0;
  cursor: pointer;
}

#field_submit:hover,
#field_submit_empl:hover {
  background: #717171;
  color: #e9e9e9;
}

.filtered {
  position: absolute;
  top: 25px;
  right: 263px;
  background: #81B32B;
  margin: 5px 0 0 0;
  font-size: 12px;
  padding: 0 0 0 10px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  width: 133px;
}

.week-planning .filtered {
  top: -2px;
}

.filtered a {
  position: absolute;
  top: 0;
  right: 0;
  height: 22px;
  line-height: 22px;
  text-decoration: none;
  color: #fff;
  background: #73A024;
  padding: 0 7px 0;
  margin: 0 0 0 5px;
}

.noresults {
  background: #AAA;
  font-size: 12px;
  padding: 0 0 0 30px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  display: block;
}

.noresults a {
  position: absolute;
  top: 0;
  right: 0;
  height: 22px;
  line-height: 22px;
  text-decoration: none;
  color: #fff;
  background: #9b1c1c;
  padding: 0 7px 0;
  margin: 0 0 0 5px;
}

.projects-index input#field_submit {
  top: -34px;
  height: 30px;
  width: 70px;
  background: #81B32B;
  color: #fff;
  font-family: myriad-pro, 'Segoe UI', arial;
  font-weight: 600;
  font-size: 12px;
}

.projects-index input#field_submit:hover {
  background: #73A024;
}

.projects-index a#field_reset {
  background: #c4c4c4;
  position: absolute;
  top: -34px;
  right: 80px;
  padding: 8px;
  width: 60px;
  cursor: pointer;
  font-size: 12px;
  color: #333;
  font-family: myriad-pro, 'Segoe UI', arial;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.projects-index a#field_reset:hover {
  background: #ddd;
}

.projects-index #field_search {
  width: calc(45% + 213px);
}

.project-filters {
  width: 100%;
  position: absolute;
  top: -52px;
}

.project-filters .row {
  width: 15%;
  margin-right: 10px;
  float: left;
}

.project-filters .row.row-datepicker {
  width: 75px;
  margin-right: 20px;
}

.project-filters .row label {
  display: block;
  padding: 2px 0;
}

.project-filters .row input.datepicker {
  width: 70px;
  height: 18.5px;
  padding: 2px 8px;
  background: #fff;
  border: 1px solid #747474;
  font-size: 12px;
  border-radius: 0;
}

.project-filters .row input#start_date_till {
  margin-top: 18px;
}

.project-filters .row .selectize-input {
  height: 25px;
  min-height: 25px;
  padding: 2px 8px;
  background: #fff;
  border: 1px solid #747474;
  font-size: 12px;
  border-radius: 0;
}

.project-filters .selectize-control.single .selectize-input:after {
  right: 8px;
}

.project-filters .selectize-control.multi .selectize-input {
  height: 75px;
}

.project-filters .selectize-dropdown {
  padding: 0;
}

.project-filters .selectize-dropdown-content {
  background: #fff;
  border: 1px solid #747474;
}

.project-filters .selectize-control.multi .selectize-input .item {
  background: #fff;
  font-size: 12px;
}

.project-filters .selectize-dropdown .option {
  font-size: 12px;
}

.project-filters .selectize-dropdown .option:hover {
  background: #e5e5e5;
}

.project-filters .row label {
  font-size: 12px;
}

.project-dropdown-filter {

}

/* ********************************************************************************************* */
/* Overzichten */
/* ********************************************************************************************* */

div.leave {
  cursor: pointer !important;
}

.team {
  padding-left: 15px !important;
}

.overzicht {
  position: relative;
  background: #fff;
  margin: -6px 0 0 0;
}

.verlof.overzicht td.last {
  padding-left: 5px;
  padding-right: 5px;
  line-height: 24px;
}

.overzicht.conflicts {
  margin-top: -35px;
}

.overzicht .shadow {
  position: absolute;
  top: 30px;
  left: 0;
}

.overzicht table {
  width: 100%;
  font-size: 12px;
  table-layout: fixed;
}

.fixed-table-header {
  background-color: #f9f9f9;
  position: fixed;
  top: 50px;
  padding-left: 222px;
  right: 0;
  width: 100%;
  display: none;
  z-index: 1;
}

.fixed-table-header th,
.fixed-table-header td {
  color: #333 !important;
  font-size: 11px;
}

.overzicht tr.odd {
}

.overzicht tr.even {
}

.overzicht tr.title {
  height: 30px;
}

.overzicht td {
  position: relative;
  border-bottom: 1px solid #000;
}

.overzicht tr.title td a {
  color: #404040;
  text-decoration: none;
}

.overzicht tr td a {
  color: #848484;
}

.overzicht td.odd,
.overzicht td.even {
  border-right: 1px solid #000;
  height: 50px;
  min-width: 124px;
}

.overzicht.inplannen td.odd,
.overzicht.inplannen td.even {
  vertical-align: top;
}

.overzicht tr.odd td.even,
.overzicht tr.even td.odd,
.overzicht tr.even td.name,
.overzicht tr.even td.first,
.overzicht tr.odd th.even,
.overzicht tr.even th.odd,
.overzicht tr.even th.name,
.overzicht tr.even th.first {
  border-right: 1px solid #000;
  height: 50px;
  font-size: 12px;
  min-width: 124px;
}

.overzicht td.contract-ended,
.overzicht td.contract-not-started {
  background: #acacac;
}

.overzicht tr.title td,
.overzicht tr.title th {
  border-bottom: 1px solid #cecece;
  color: #404040;
  height: 30px;
  padding: 0 0 0 5px;
  font-weight: normal;
}

.overzicht tr.title a.asc {
  padding: 0 15px 0 0;
}

.overzicht tr.title a.asc::before {
  font-family: 'FontAwesome';
  content: '\f0d7';
  margin-right: 5px;
}

.overzicht tr.title a.desc {
  padding: 0 15px 0 0;
}

.overzicht tr.title a.desc::before {
  font-family: 'FontAwesome';
  content: '\f0d8';
  margin-right: 5px;
}

.overzicht tr.top_title td {
  color: #404040;
  height: 15px;
  padding: 0 0 0 5px;
  border: 0;
}

.overzicht tr.top_title td.name {
  border: 0;
  color: #404040;
  height: 15px;
  padding: 0 0 0 5px;
}

.overzicht td.name {
  border-right: 1px solid #000;
  border-left: 0;
  height: 30px;
  width: 200px;
  min-width: 70px;
  color: #848484;
  padding: 0 0 0 30px;
}

.overzicht td.name i {
  width: 17px;
}

.overzicht tr.title td.name {
  padding-left: 0;
  border-right: 0;
}

.overzicht td.first,
.overzicht th .first {
  padding: 0;
  width: 25px;
  font-size: 12px;
  min-width: 5px;
  border-right: 0 !important;
}

.overzicht tr.title td.last {
  border-right: none;
}

.overzicht td.last,
.overzicht th.last {
  padding: 0;
  height: 50px;
  width: 25px;
  min-width: 25px;
  border-right: none !important;
  border-bottom: 1px solid #000;
}

.overzicht td.perc {
  font-size: 10px;
  color: #666;
  text-align: center;
  vertical-align: middle !important;
}

.overzicht tr.totals td {
  height: 30px;
  font-size: 14px;
  line-height: 15px;
  padding: 5px;
}

.overzicht tr.totals.num-weeks-4 td {
  font-size: 11px;
}

.overzicht tr.totals td span {
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 20px;
}

.overzicht tr.totals td span.minus {
  color: #f50700;
}

.overzicht tr.totals td label {
  text-align: left;
  float: left;
}

.overzicht tr.totals td hr {
  width: calc(100% - 10px);
  margin-top: 5px;
  border: 5px solid #dcd9dc;
}

.overzicht tr.totals td hr.planned-line {
  position: relative;
  top: -15px;
  max-width: calc(100% - 10px);
  border-color: #79BD65;
}

.overzicht tr.totals td hr.planned-line.red {
  border-color: #f50700;
  max-width: 95%;
}

.overzicht tr.totals td.name {
  text-align: left;
}

.grey,
.grey_row .item,
.gray,
.gray_row .item {
  background-color: #9A9A9A !important;
  /* Old browsers */
}

.brown,
.brown_row .item {
  background-color: #A07837 !important;
  /* Old browsers */
}

.purple,
.purple_row .item {
  background-color: #B965BC !important;
  /* Old browsers */
}

.light_green,
.light_green_row .item {
  background-color: #79BD65 !important;
  /* Old browsers */
}

.green,
.green_row .item {
  background-color: #3e9c41 !important;
  /* Old browsers */
}

.green.gradient {
  background: #7bd75e;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzdiZDc1ZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2M2JlNDgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #7bd75e 0%, #63be48 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #7bd75e), color-stop(100%, #63be48));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #7bd75e 0%, #63be48 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #7bd75e 0%, #63be48 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #7bd75e 0%, #63be48 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #7bd75e 0%, #63be48 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7bd75e', endColorstr='#63be48', GradientType=0);
  /* IE6-8 */
}

.red,
.red_row .item {
  background-color: #d34f50 !important;
  /* Old browsers */
}

.red.gradient {
  background: #d34f50;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2QzNGY1MCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlMDA3MWUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #d34f50 0%, #e0071e 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d34f50), color-stop(100%, #e0071e));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #d34f50 0%, #e0071e 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #d34f50 0%, #e0071e 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #d34f50 0%, #e0071e 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #d34f50 0%, #e0071e 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d34f50', endColorstr='#e0071e', GradientType=0);
  /* IE6-8 */
}

.orange,
.orange_row .item {
  background-color: #f38c47 !important;
  /* Old browsers */
}

.pink,
.pink_row .item {
  background-color: #FEA3B2 !important;
  /* Old browsers */
}

.orange.gradient {
  background: #f38c47;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YzOGM0NyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZTZhMDciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #f38c47 0%, #fe6a07 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f38c47), color-stop(100%, #fe6a07));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f38c47 0%, #fe6a07 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f38c47 0%, #fe6a07 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f38c47 0%, #fe6a07 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #f38c47 0%, #fe6a07 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f38c47', endColorstr='#fe6a07', GradientType=0);
  /* IE6-8 */
}

.yellow,
.yellow_row .item {
  background-color: #FAD165 !important;
  /* Old browsers */
}

.black {
  background-color: #858585 !important;
  /* Old browsers */
}

.blue {
  background-color: #6fa5cc !important;
}

.light_blue {
  background-color: #62d7e3 !important;
}

.black {
  background-color: black !important;
}

.aquamarine {
  background-color: aquamarine !important;
}

.crimson {
  background-color: crimson !important;
}

.darkorange {
  background-color: darkorange !important;
}

.greenyellow {
  background-color: greenyellow !important;
}

.lightcoral {
  background-color: lightcoral !important;
}

.lightseagreen {
  background-color: lightseagreen !important;
}

.orangered {
  background-color: orangered !important;
}

.nocolor,
.nocolor_row .item {
  background-color: #9A9A9A;
  /* Old browsers */
}

.nocolor.company-26 {
  background-color: #FFF !important;
}

/*
red
orange
yellow
pink
blue
light_blue
green
light_green
purple
brown
grey
*/

.overzicht table > tr > td .item,
.overzicht.inplannen td .item {
  position: relative;
  float: left;
  padding: 5px 0;
  z-index: 1;
  moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.overzicht.inplannen:not(.advanced-week-planning) td .item,
.overzicht table > tr > td .item {
  height: 50px;
}

.overzicht td .item.rounded-right-borders {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.overzicht td .item.rounded-left-borders {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.overzicht td .tekst_wrapper {
  table-layout: fixed;
  padding: 0 5px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
}

.overzicht:not(.advanced-week-planning) td .tekst_wrapper {
  height: 40px;
}

.overzicht td .tekst_wrapper.new {
  background: orange;
}

.overzicht td .tekst {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed;
}

.overzicht td .tekst span {
  display: table-cell;
  vertical-align: middle;
  font-size: 11px;
  font-weight: bold;
  line-height: 18px;
}

.overzicht td .company-38 .tekst span {
  line-height: 13px;
}

.overzicht td .tekst span hr {
  border-color: rgba(0, 0, 0, 0.53);
  border-top: 0;
}

.overzicht td .tekst span a {
  color: #000;
  text-decoration: none;
}

.overzicht td .edit {
  position: absolute;
  right: -1px;
  top: -1px;
  width: 15px;
  height: 15px;
  background-color: #333;
  display: none;
  font-family: 'FontAwesome';
  color: #fff;
  padding-left: 5px;
  padding-top: 5px;
}

.overzicht td .edit::before {
  content: "\f0c9";
}

#PlanningWeekForm .overzicht td .tt.item:hover {
  cursor: move !important;
}

.overzicht td .tt.item:not(.not-editable):not(.closed):hover .edit {
  display: block;
}

.overzicht td .pattern_wrapper {
  position: relative;
}

.overzicht td .pattern {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*background: url(/img/patterns/pattern.png) repeat;*/
}

tr.project-leader-row {
  background: #efefef;
}

td.project-leader.name {
  padding-left: 0;
  font-size: 13px;
  font-weight: bold;
}

.item.closed.company-44 {
  opacity: 0.5;
}

.indefinite.company-38 .pattern,
.indefinite.company-91 .pattern,
.indefinite.company-110 .pattern {
  background: repeating-linear-gradient(-45deg, rgba(249, 249, 249, .3), rgba(249, 249, 249, .3) 5px, rgba(218, 218, 218, .3) 5px, rgba(218, 218, 218, .3) 10px);
}

.item.selected {
  border: 2px dashed #fff;
}

.item.company-44.completed {
  background: repeating-linear-gradient(+45deg, rgba(249, 249, 249, 1), rgba(249, 249, 249, 1) 5px, rgba(218, 218, 218, 0) 5px, rgba(218, 218, 218, 0) 10px);
}

.selected .pattern {
  background: rgba(255, 255, 255, 0.35);
}

.overzicht td.actions {
  width: 202px;
  padding-right: 0 !important;
}

.overzicht td.small.actions {
  width: 95px !important;
}

.overzicht td.actions a.button {
  float: left;
  width: 85px;
  height: 17px;
  line-height: 17px;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #000;
}

.overzicht td.actions a.button.button_inplannen {
  background: #c4c4c4;
  color: #000;
}

.actions a.button.delete {
  background: #EA4E43;
  /* Old browsers */
  /*background: -moz-linear-gradient(left,  #c02626 0%, #eb2f2f 100%); !* FF3.6+ *!*/
  /*background: -webkit-gradient(linear, left top, right top, color-stop(0%,#c02626), color-stop(100%,#eb2f2f)); !* Chrome,Safari4+ *!*/
  /*background: -webkit-linear-gradient(left,  #c02626 0%,#eb2f2f 100%); !* Chrome10+,Safari5.1+ *!*/
  /*background: -o-linear-gradient(left,  #c02626 0%,#eb2f2f 100%); !* Opera 11.10+ *!*/
  /*background: -ms-linear-gradient(left,  #c02626 0%,#eb2f2f 100%); !* IE10+ *!*/
  /*background: linear-gradient(to right,  #c02626 0%,#eb2f2f 100%); !* W3C *!*/
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c02626', endColorstr='#eb2f2f',GradientType=1 ); !* IE6-9 *!*/
  color: #f9f9f9;
  /*margin: 0 0 0 7px;*/
}

.actions a.button.delete:hover {
  background: #DE5045;
}

.overzicht .title td.type {
  width: 50px;
  padding: 0;
}

.overzicht td.type {
  text-align: left;
  padding-left: 7px;
  padding-right: 31px;
  background: none !important;
}

.overzicht td.type span {
  width: 10px;
  height: 10px;
  display: inline-block;
}

/* ********************************************************************************************* */
/* Small Rows */
/* ********************************************************************************************* */

.overzicht tr.small_row td,
.overzicht tr.small_row td .item {
  height: 30px !important;
}

.overzicht tr.small_row td .tekst_wrapper {
  height: 20px;
}

/* ********************************************************************************************* */
/* Verlof overzicht */
/* ********************************************************************************************* */

.overzicht.verlof_overzicht {
  /**/
}

.overzicht.verlof_overzicht tr {
  color: #848484;
}

.overzicht.verlof_overzicht tr:hover {
  color: #000;
}

.bottom_content .overzicht.verlof_overzicht tr.title td {
  padding: 0 5px;
}

.bottom_content #title i {
  float: left;
}

.overzicht.verlof_overzicht td {
  border-bottom: 1px solid #cecece;
  border-right: 0;
  height: 18px;
  line-height: 18px;
  padding: 5px;
}

.overzicht.verlof_overzicht tr.odd td.even,
.overzicht.verlof_overzicht tr.even td.odd,
.overzicht.verlof_overzicht tr.even td.name,
.overzicht.verlof_overzicht tr.even td.first {
  height: auto;
  background: none;
  border-right: 0;
}

.overzicht.verlof_overzicht tr.even {
}

.overzicht.verlof_overzicht td.small {
  width: 110px;
}

.overzicht.verlof_overzicht td.nummer {
  width: 100px;
}

.overzicht.verlof_overzicht td.hours {
  width: 60px;
}

.overzicht.verlof_overzicht td.status {
  width: 70px;
}

.overzicht.verlof_overzicht td.date {
  width: 110px;
}

.overzicht.verlof_overzicht td.week {
  width: 50px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.overzicht.verlof_overzicht td.text {
  width: 190px;
}

.overzicht.overzicht td.opmerking {
  min-width: 200px;
}

.overzicht.verlof_overzicht td.te_plannen {
  width: 230px;
}

.overzicht.verlof_overzicht td.te_plannen .progress_bar {
  float: left;
  width: 148px;
  height: 13px;
  margin: 2px 0;
  border: 1px solid #737373;
}

.overzicht.verlof_overzicht td.te_plannen .progress_bar .bar {
  position: relative;
  height: 13px;
}

.overzicht.verlof_overzicht td.te_plannen span {
  line-height: 18px;
  padding: 0 0 0 5px;
  font-size: 11px;
}

.overzicht.verlof_overzicht td.actions {
}

.overzicht.verlof_overzicht td.actions a.button {
  float: left;
  width: 25px;
  height: 17px;
  font-size: 13px;
  text-align: center;
  text-decoration: none;
  /*background: none;*/
}

.overzicht.verlof_overzicht td.buttons a.button.button_inplannen {
  background: #c4c4c4;
  color: #000;
}

.verlof.overzicht th.small {
  font-size: 8px;
}

/* ********************************************************************************************* */
/* Bottom Content */
/* ********************************************************************************************* */

.bottom_content {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /*min-width: 1024px;*/
  padding: 0 0 0 220px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10;
}

.bottom_content .shadow_top {
  position: absolute;
  display: block;
  width: 100%;
  height: 5px;
  top: -6px;
}

.bottom_content .shadow {
  display: block;
  width: 100%;
  height: 5px;
}

.bottom_content .wrapper {
  background: #fff;
  padding: 0 30px 30px 30px;
}

.bottom_content .wrapper.padding {
  padding: 30px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.bottom_content #title {
  padding: 30px;
  font-size: 24px;
  line-height: 20px;
  font-weight: 600;
  background: #fff;
}

.bottom_content #title .icon {
  float: left;
  height: 20px;
}

.bottom_content #title .title {
  float: left;
  margin: 0 0 0 10px;
}

.bottom_content #title .title a {
  color: #000;
  text-decoration: none
}

.bottom_content #title .title a:hover {
  text-decoration: underline;
}

.bottom_content #title .info {
  font-size: 12px;
  font-weight: normal;
}

/* ********************************************************************************************* */
/* Deelprojecten */
/* ********************************************************************************************* */

.push {
  height: 91px;
}

.push_shadow {
  height: 86px;
}

.overzicht.deelprojecten tr {
  color: #848484;
}

.overzicht.deelprojecten tr.expanded {
  background: #c1c1c1;
  color: #000 !important;
}

.overzicht.deelprojecten tr.child-row {
  display: none;
  background: #e6e6e6 !important;
}

.overzicht.deelprojecten tr.child-row .button.load {
  margin-right: 46px;
}

.overzicht.deelprojecten .show-children {
  position: absolute;
  top: 4px;
  right: -15px;
  z-index: 9;
}

.overzicht.deelprojecten tr:hover {
  color: #000;
}

.overzicht.deelprojecten tr.title td {
  padding: 0 5px;
}

.overzicht.deelprojecten td {
  border-bottom: 1px solid #cecece;
  height: 18px;
  line-height: 18px;
  padding: 5px;
}

.overzicht.deelprojecten td select {
  max-width: -webkit-fill-available;
}

.overzicht.deelprojecten td.short {
  text-align: center;
  width: 100px
}

.overzicht.deelprojecten tr td.first {
  text-align: right;
}

.overzicht.deelprojecten tr.odd td.even,
.overzicht.deelprojecten tr.even td.odd,
.overzicht.deelprojecten tr.even td.name,
.overzicht.deelprojecten tr.even td.first {
  height: auto;
  background: none;
}

.overzicht.deelprojecten tr.odd {
  background: #F9FAFB;
}

.overzicht.deelprojecten tr.even {
}

.overzicht.deelprojecten tr.green {
  background: #dff0d8 !important;
}

.overzicht.deelprojecten td.actions a.button {
  float: left;
  width: 25px;
  font-size: 13px;
  text-align: center;
  text-decoration: none;
  /*background: none;*/
}

.overzicht.deelprojecten td.actions a.button.button_inplannen {
  background: #c4c4c4;
  color: #000;
}

.overzicht.deelprojecten td.actions a.button.delete {
  color: #f9f9f9;
}

/* ********************************************************************************************* */
/* Dagplanning */
/* ********************************************************************************************* */

.overzicht.dagplanning {
  color: #404040;
}

.overzicht.dagplanning td.time {
  width: 60px;
  text-align: center;
  padding: 0;
}

.overzicht.dagplanning.ajax td.time {
  width: 95px;
}

.overzicht.dagplanning td.name {
  padding-left: 0;
}

.overzicht.dagplanning td {
  padding-left: 5px;
  padding-right: 5px;
  line-height: 24px;
}

.overzicht.dagplanning.ajax td {
  line-height: 12px;
}

.overzicht.dagplanning td.time .item {
  height: 100%;
  padding: 0;
}

.overzicht.dagplanning td .tekst_wrapper {
  overflow: visible;
  height: auto;
  text-align: center;
  margin: 5px;
}

#presentation_wrapper.company-32 .tekst_wrapper {
  background-color: transparent !important;
}

.overzicht.dagplanning td .tekst,
.overzicht.dagplanning td.tekst span {
  width: 100%;
  padding: 10px 0;
}

.overzicht.dagplanning td .tekst,
.overzicht.dagplanning td.tekst span {
  width: 100%;
  padding: 10px 0;
}

#presentation_wrapper.company-32 .overzicht.dagplanning td .tekst,
#presentation_wrapper.company-32 .overzicht.dagplanning td .tekst span,
#presentation_wrapper.company-32 tr th,
#presentation_wrapper.company-32 tr td {
  font-size: 130%;
  line-height: 130%;
  font-weight: bold;
}

#presentation_wrapper.company-32 .tekst_wrapper {
  background-color: transparent !important;
}

/* ********************************************************************************************* */
/* Project Overzicht */
/* ********************************************************************************************* */

.overzicht.project_overzicht {
}

.overzicht.project_overzicht tr {
  color: #848484;
}

.overzicht.project_overzicht tr:hover {
  color: #000;
}

.bottom_content .overzicht.project_overzicht tr.title td {
  padding: 0 5px;
}

.overzicht.project_overzicht td {
  border-bottom: 1px solid #cecece;
  height: 18px;
  line-height: 18px;
  padding: 5px;
}

.overzicht.project_overzicht tr.odd td.even,
.overzicht.project_overzicht tr.even td.odd,
.overzicht.project_overzicht tr.even td.name,
.overzicht.project_overzicht tr.even td.first {
  height: auto;
  background: none;
}

.overzicht.project_overzicht tr.odd {
  background: #F9FAFB;
}

.overzicht.project_overzicht tr.even {
}

.overzicht.project_overzicht td.small {
  width: 110px;
}

.overzicht.project_overzicht td.smaller {
  width: 40px;
}

.overzicht.project_overzicht td.nummer {
  width: 100px;
}

.overzicht.project_overzicht td.hours {
  width: 70px;
}

.overzicht.project_overzicht td.planned_hours {
  width: 95px;
}

.overzicht.project_overzicht td.status {
  width: 70px;
}

.overzicht.project_overzicht td.invc_invoice_status {
  width: 80px;
}

.overzicht.project_overzicht td.date {
  width: 80px;
}

.overzicht.project_overzicht td.week {
  width: 50px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.overzicht.project_overzicht td.project {
  width: 190px;
}

.overzicht.project_overzicht td.machine {
  width: 150px;
}

.overzicht.project_overzicht td.text {
  width: 190px;
}

.overzicht.project_overzicht td.te_plannen {
  width: 230px;
}

.overzicht.project_overzicht td.te_plannen .progress_bar {
  float: left;
  width: 148px;
  height: 13px;
  margin: 2px 0;
  border: 1px solid #737373;
}

.overzicht.project_overzicht td.te_plannen .progress_bar .bar {
  position: relative;
  height: 13px;
}

.overzicht.project_overzicht td.te_plannen span {
  line-height: 18px;
  padding: 0 0 0 5px;
  font-size: 11px;
}

.overzicht.project_overzicht td.actions {
}

.overzicht.project_overzicht td.actions a.button {
  float: left;
  width: 25px;
  font-size: 13px;
  text-align: center;
  text-decoration: none;
  /*background: none;*/
}

.overzicht.project_overzicht td.actions a.button.delete {
  color: #f9f9f9;
}

.overzicht.project_overzicht td.buttons a.button.button_inplannen {
  background: #c4c4c4;
  color: #000;
}

/* ********************************************************************************************* */
/* Form */
/* ********************************************************************************************* */

.form {
  /**/
}

.form .row {
  position: relative;
  margin: 0 0 5px 0;
}

.form .row.required {
}

.form .row.required label:before {
  content: "* ";
  color: red;
  font-size: 18px;
  line-height: 12px;
}

.form .row:last-child {
  margin: 0;
}

.form input:focus,
.form textarea:focus {
  outline: none;
  font-family: "Segoe UI", arial;
}

.form label {
  float: left;
  font-size: 12px;
  text-align: right;
  width: 110px;
  line-height: 25px;
  margin: 0 5px 0 0;
}

.form.import label {
  width: auto;
}

.form input[type=number],
.form .form-text {
  float: left;
  background: #f5f5f5;
  width: 110px;
  border: 1px solid #747474;
  height: 23px;
  font-size: 12px;
  color: #434343;
  line-height: 23px;
  padding: 0 5px;
}

.form .form-select {
  float: left;
  background: #f5f5f5;
  width: 122px;
  border: 1px solid #747474;
  height: 23px;
  font-size: 12px;
  color: #434343;
  line-height: 23px;
  padding: 0 0 0 5px;
}

.form .inactive {
  background: #c6c6c6 !important;
  -webkit-text-fill-color: #434343;
}

.form .form-textarea {
  background: #f5f5f5;
  border: 1px solid #747474;
  font-size: 12px;
  color: #434343;
  line-height: 18px;
  padding: 5px;
  width: 80%;
  resize: none;
}

.form .radio {
  font-size: 12px;
  line-height: 25px;
}

.form .radio input {
  width: auto;
  float: left;
  margin: 5px 5px 0 5px;
}

.form .radio div {
  width: auto;
  float: left;
  margin-right: 15px;
}

.form .check input {
  margin: 6px 0 0 0;
}

.text-submit {
  background: none;
  border: none;
  float: right;
  text-decoration: underline;
}

.text-submit:hover {
  text-decoration: none;
  cursor: pointer;
}

form .form-submit.bulk-delete-deelprojecten,
.form .form-submit.form-submit-right.bulk-delete-projects {
  background: #ea4e43;
  color: #fff;
}

.form .form-submit.bulk-delete-deelprojecten:hover {
  background: #DE5045;
}

.form .form-submit.bulk-delete-deelprojecten:disabled {
  background: #CCCCCC;
  color: #000;
  cursor: not-allowed;
}

.form .form-submit,
.form .form-submit-left,
.form .form-submit-right,
.form .form-delete,
.form .form-cancel,
.btn {
  float: right;
  height: 35px;
  width: auto !important;
  line-height: 35px;
  padding: 0 20px;
  margin: 0 0 0 10px;
  border: 0;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.form .form-submit-left {
  float: left;
  margin: 30px;
}

.form .form-submit-right {
  float: right;
  margin: 30px;
}

.form .form-submit-right .new-project {
  position: absolute;
  right: 0;
  top: 0;
}

.form .form-submit.project_afronden {
  background: #fb8c1e;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZiOGMxZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjdmMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(left, #fb8c1e 0%, #ff7f00 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #fb8c1e), color-stop(100%, #ff7f00));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #fb8c1e 0%, #ff7f00 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #fb8c1e 0%, #ff7f00 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #fb8c1e 0%, #ff7f00 100%);
  /* IE10+ */
  background: linear-gradient(to right, #fb8c1e 0%, #ff7f00 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fb8c1e', endColorstr='#ff7f00', GradientType=1);
  /* IE6-8 */
}

.form .form-submit.project_afronden:hover {
  background: #d17418;
}

.form .form-submit.nieuw_deelproject {
  background: #cccccc;
  color: #000;
}

.form .form-submit.nieuw_deelproject:hover {
  background: #999999;
}

.form .form-submit,
.form .form-submit-left,
.form .form-submit-right,
.btn {
  background: #81B32B;
}

.btn a {
  color: #fff;
}

.btn.btn-default {
  background: #ccc;
  border-color: #ccc;
}

.btn.btn-photos,
.btn.btn-hours {
  float: left;
  margin: 25px 0 0 0;
  width: 320px !important;
  border: 1px solid #cecece;
  background: #f5f5f5;
  color: #000;
  cursor: pointer;
  height: 42px;
  line-height: 42px;
}

.btn.btn-photos:hover,
.btn.btn-hours:hover {
  background: #999;
}

.btn.btn-photos .num-photos {
  color: #999;
  font-size: 12px;
  font-style: italic;
  position: absolute;
  padding: 0 5px;
}

.delete-photo {
  color: #e24141;
}

.form .form-submit:hover,
.form .form-submit-left:hover,
.form .form-submit-right:hover,
.btn:hover {
  background: #73A024;
}

.form .form-cancel {
  background: none;
  color: #7a7a7a;
}

.form .form-cancel:hover {
  text-decoration: underline;
}

.form .form-delete {
  background: #CC0000;
  float: left;
}

/* ********************************************************************************************* */
/* Paginering */
/* ********************************************************************************************* */

#pagination {
  margin: 30px;
  font-size: 12px;
}

#pagination .disabled {
  display: none;
}

#pagination span {
  float: left;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #81B32B;
  color: #fff;
  text-align: center;
  margin: 0 5px 0 0;
}

#pagination span.next,
#pagination span.prev {
  width: auto;
}

#pagination span.next a,
#pagination span.prev a {
  padding: 0 10px;
}

#pagination a {
  display: block;
  color: #fff;
  text-decoration: none;
}

#pagination span.current,
#pagination a:hover {
  background: #73A024;
}

#pagination.form .form-submit-right {
  margin: 0;
}

/* ********************************************************************************************* */
/* Week filter */
/* ********************************************************************************************* */

.filter {
  position: absolute;
  top: 72px;
  right: 0;
  font-size: 16px;
  font-weight: normal;
  /*width: 200px;*/
}

.dayplanning-page #page_title .filter,
.projectplanning-page #page_title .filter,
.dayteamplanning-page #page_title .filter,
.teamplanning-page #page_title .filter {
  top: 68px;
}

/*#page_title .filter img {*/
/*margin: 0 10px 0 10px;*/
/*border: 0;*/
/*}*/

.filter .load {
  color: #333;
  text-decoration: none;
}

.filter i {
  margin: 0 10px 0 10px;
  border: 0;
}

.week_filter {
  cursor: pointer;
  padding: 0 10px;
}

#weekfilter {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  /* background: #dadada; */
  padding: 5px 0;
  margin-top: -10px;
  margin-bottom: 10px
}

/* ********************************************************************************************* */
/* Week selector */
/* ********************************************************************************************* */

#numWeeksSelector {
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  text-align: center;
  margin-top: -5px;
}

#numWeeksSelector span {
  margin: 0 0 0 3px;
}

ol#numWeeks {
  display: inline;
}

ol#numWeeks li {
  display: inline-block;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  width: 30px;
  margin: 0 -5px;
}

ol#numWeeks li:hover,
ol#numWeeks li.active {
  font-weight: bold;
  font-size: 14px;
  /*margin: 0 -5px;*/
  color: #333;
}

/* ********************************************************************************************* */
/* Presentation view */
/* ********************************************************************************************* */

#presentation_wrapper {
  z-index: 9999;
  position: relative;
  padding-top: 55px;
  width: 100%;
}

#presentation_wrapper .new {
  z-index: 10;
  position: absolute;
  display: none;
  width: 100%;
}

#presentation_wrapper .current {
  z-index: 1;
  position: absolute;
  width: 100%;
}

#presentation_wrapper .current::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

#presentation_wrapper .table {
  margin-bottom: 50px;
}

#presentation_wrapper.company-32 .overzicht.dagplanning td .tekst,
#presentation_wrapper.company-32 .overzicht.dagplanning td .tekst span,
#presentation_wrapper.company-32 tr th,
#presentation_wrapper.company-32 tr td {
  font-size: 130%;
  line-height: 130%;
  font-weight: bold;
}

#presentation_wrapper.company-32 .tekst_wrapper {
  background-color: transparent !important;
}

#leaves_wrapper {
  position: absolute;
  bottom: 60px;
}

#leaves_wrapper h2 {
  padding-left: 20px;
  margin-bottom: 20px;
  color: #333;
}

#presentation_scroller {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 5px 0 0 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #343434;
  font-size: 30px;
  z-index: 99999;
}

/* ********************************************************************************************* */
/* Conflict */
/* ********************************************************************************************* */

.modal {
  display: none;
}

#conflicts,
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999999;
  text-align: center;
}

#conflicts ul,
#conflicts div,
.modal > div {
  position: absolute;
  z-index: 101;
  background: #FFF;
  padding: 20px;
  border: 1px solid #777;
  list-style: none;
  overflow-y: auto;
  max-height: 100%;
}

.modal > div {
  padding: 30px;
}

#conflicts .title,
.modal .title {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}

.modal .description {
  margin: 15px 0;
}

#conflicts table {
  padding: 15px 0;
  font-size: 14px;
  line-height: 24px;
}

#conflicts .left_text {
  text-align: right;
}

#conflicts .middle {
  width: 10px;
}

#conflicts .right_text {
  text-align: left;
  color: #747474;
}

.modal form {
  padding: 25px 0;
}

.modal form .row {
  margin: 5px;
}

.modal form label {
  width: 120px;
  display: inline-block;
  margin-right: 10px;
  text-align: right;
}

.modal.increase-hours input:not([type=radio]) {
  width: 150px !important;
  padding: 3px;
  font-size: 12px;
}

#conflicts .button,
.modal .button,
.button.primary {
  height: 35px;
  line-height: 35px;
  padding: 5px 20px;
  margin: 0 0 0 10px;
  border: 0;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  background: #81B32B;
}

/* ********************************************************************************************* */
/* Bewerken blok */
/* ********************************************************************************************* */
#blockedit {
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  width: 265px;
  height: 94px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999999;
  font-size: 12px;
  padding: 42px 0 0 15px;
  display: none;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

#blockedit.week {
  padding-bottom: 5px;
}

#blockedit .row {
  margin-bottom: 8px;
  float: left;
}

#blockedit select {
  float: left;
  margin-right: 10px;
  width: 250px;
}

#blockedit .form-datetime {
  float: left;
}

#blockedit label {
  float: left;
  margin-right: 3px;
  margin-top: 2px;
}

#blockedit textarea {
  width: 250px;
}

#blockedit .arrow {
  position: absolute;
  margin-top: 125px;
  margin-left: 230px;
  z-index: 9998;
  top: 11px;
  left: 11px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 6.5px 0 6.5px;
  border-color: #fafafa transparent transparent transparent;
}

#blockedit .datepicker {
  float: left;
  width: 72px;
  margin-right: 10px;
}

#blockedit .combobox_selector {
  top: 20px !important;
  z-index: 9999;
}

#blockedit .button.delete {
  margin: 0 15px 0 0;
}

#blockedit .cancel {
  margin: 0px 9px 0 0;
}

#blockedit .cancel i {
  color: #fff;
  font-size: 13px;
  margin-top: -2px;
}

#blockedit.week .arrow {
  margin-top: 251px;
}

#blockedit .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 263px;
  height: 30px;
  line-height: 30px;
  background: #000;
  color: #FFF;
  padding: 0 0 0 15px;
  border: 1px solid #000;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

#blockedit .title a {
  float: right;
}

/** TOEVOEGEN BLOK **/
#block-add {
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  width: 265px;
  height: 235px;
  position: fixed;
  left: 500px;
  top: 200px;
  z-index: 9999999;
  font-size: 12px;
  padding: 42px 0 10px 15px;
  display: none;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;

  overflow: visible;
}

#block-add.company-44 {
  display: none;
  width: 150px;
  height: 70px;
  border: 1px solid gainsboro;
}

#block-add.company-44 .title {
  width: 150px;
}

#block-add.create-plannings {
  width: 350px;
  height: inherit !important;
  padding-right: 15px;
}

#block-add #FormAddNewPlannings .save {
  margin-top: 15px;
}

#block-add .choice {
  margin-top: -10px;
  margin-left: -15px;
  width: 280px !important;
}

#block-add .choice .option {
  width: 120px;
  float: left;
  background: #dadada;
  padding: 5px 10px;
  color: #000;
}

#block-add .choice .option:not(.active):hover {
  cursor: pointer;
  background-color: #f9f9f9;
}

#block-add .choice .option.active {
  background: #f9f9f9;
  /*color: #fff;*/
  font-weight: bold;
}

#block-add .leave-request-wrapper {
  display: none;
}

#block-add #FormAddNewPlannings #BlockCheckDelete {
  float: left;
  margin: 4px 4px 0 0;
}

#block-add.planning {
  height: 350px;
}

#blockedit .row,
#block-add .row {
  margin-bottom: 8px;
  float: left;
  width: 100%;
}

#blockedit select,
#blockedit .selectize-control,
#block-add select,
#block-add .selectize-control {
  float: left;
  margin-right: 10px;
  width: 250px;
}

#blockedit textarea,
#block-add textarea {
  width: 243px;
  padding: 3px 0 0 5px;
}

#blockedit .selectize-input,
#block-add .selectize-input {
  border-color: #A9A9A9;
  border-radius: 0;
  padding: 0 0 0 5px;
  min-height: 25px;
}

#block-add .form-datetime {
  float: left;
}

#block-add label {
  float: left;
  margin-right: 3px;
  margin-top: 2px;
}

#block-add .arrow {
  position: absolute;
  margin-top: 125px;
  margin-left: 230px;
  z-index: 9998;
  top: 11px;
  left: 11px;
}

#block-add .datepicker {
  float: left;
  width: 72px;
  margin-right: 10px;
}

#block-add .combobox_selector {
  top: 20px !important;
  z-index: 9999;
}

#block-add .button.delete {
  margin: 0 15px 0 0;
}

#block-add .cancel {
  margin: 0 9px 0 0;
}

#block-add .arrow {
  margin-top: 165px;
}

#block-add .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 263px;
  height: 30px;
  line-height: 30px;
  background: #000;
  color: #FFF;
  padding: 0 0 0 15px;
  border: 1px solid #000;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

#block-add .cancel i {
  color: #fff;
  font-size: 13px;
}

#block-add.create-plannings .title {
  width: 363px;
}

#block-add .title a {
  float: right;
}

#block-add .actions a.disabled {
  background: #545454;
  cursor: default;
  color: #dadada;
}

.ui-progressbar {
  position: relative;
}

.progress-label {
  position: absolute;
  left: 50%;
  top: 4px;
  font-weight: bold;
  text-shadow: 1px 1px 0 #fff;
}

.project_data {
}

.customer_data {
}

.toggleAutoScroll {
  position: fixed;
  top: 10px;
  right: 25px;
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  display: none;
  z-index: 999999;
}

.toggleAutoScroll.green {
  background: #81B32B;
}

.toggleAutoScroll.red {
  background: #CC0000;
}

.sticky-wrapper.sticky {
  position: fixed;
  top: 50px;
  left: 220px;
  right: 0;
  z-index: 9999999;
}

.week-planning .sticky-wrapper.sticky {
  background: #ffffff;
  padding-top: 50px;
}

.dayplanning-page .sticky-wrapper.sticky {
  background: #ffffff;
  padding-top: 30px;
}

.sticky-wrapper.sticky #project_nav {
  top: 0 !important;
}

.sticky-wrapper.sticky #project_nav .plannings {
  z-index: 99999;
}

.dayplanning-page .sticky-wrapper.sticky .filter {
  top: 18px;
}

.mobile-button {
  display: none;
}

@media only screen and (max-device-width: 1024px) {
  .mobile-button {
    display: block;
  }
}

.week-print-button {
  position: fixed;
  top: 42px;
  right: 540px;
}

.advanced-week-button {
  position: fixed;
  top: 0;
  right: 240px;
}

.toggle-colors-button {
  position: fixed;
  top: 0;
  right: 490px;
}

.switch {
  background: #e9e9e9;
  border: 2px solid #212121;
  padding: 7px 14px;
  color: #212121;
  text-decoration: none;
  float: right;
  margin-right: 10px;
  margin-top: 7px;
  font-size: 12px;
  font-weight: bold;
}

.switch.off {
  border: 2px solid #EA4E43;
}

.switch.off:hover {
  background: #EA4E43;
  color: #f9f9f9;
}

.switch.on {
  border: 2px solid #81B32B;
}

.switch.on:hover {
  background: #81B32B;
}

#content_wrapper .content:not(.fix):not(.bottom_content):not(.edit_project):not(.import_project):not(.edit_user):not(.edit_hour):not(.edit_leaverequest):not(.configurations-page):not(.machines-page):not(.edit_machine):not(.weekplanning):not(.dayplanning) {
  width: calc(100% - 260px);
  position: relative;
  top: -65px;
  background: none;
  border: none;
}

#content_wrapper.projectplanning-page .content:not(.bottom_content),
#content_wrapper.projects-page .content:not(.bottom_content),
#content_wrapper.users-page .content:not(.bottom_content),
#content_wrapper.teamplanning-page .content:not(.bottom_content),
#content_wrapper.machineplanning-page .content:not(.bottom_content) {
  top: -61px;
}

#content_wrapper.projectplanning-page #project_nav,
#content_wrapper.projects-page #project_nav,
#content_wrapper.teamplanning-page #project_nav,
#content_wrapper.leaverequests-page #project_nav,
#content_wrapper.machineplanning-page #project_nav,
#content_wrapper.leaveplanning-page #project_nav {
  top: 37px;
  z-index: 0;
}

#content_wrapper.projectplanning-page .overzicht,
#content_wrapper.projects-page .overzicht,
#content_wrapper.teamplanning-page .overzicht,
#content_wrapper.leaverequests-page .overzicht,
#content_wrapper.machineplanning-page .overzicht,
#content_wrapper.leaveplanning-page .overzicht,
#content_wrapper form#PlanningMachineForm {
  margin-top: -26px;
}

#content_wrapper.dayplanning-page .overzicht {
  margin-top: 19px;
}

#content_wrapper.projects-index .overzicht {
  margin-top: -44px;
}

#content_wrapper.users-page .overzicht,
#content_wrapper.machines-page .overzicht,
#content_wrapper.specialdays-page .overzicht {
  margin-top: -55px;
}

#content_wrapper.projects-page .content:not(.bottom_content),
#content_wrapper.leaverequests-page .content:not(.bottom_content),
#content_wrapper.users-page .content:not(.bottom_content),
#content_wrapper.machines-page .content:not(.bottom_content),
#content_wrapper.specialdays-page .content:not(.bottom_content) {
  width: calc(100% - 25px) !important;
}

#content_wrapper:not(.ajax) {
  padding-top: 50px;
}

#content_wrapper.ajax:not(.week-planning) {
  padding-top: 5px;
}

.week-planning #planningContainer {
  padding-top: 30px;
}

#content_wrapper.ajax.week-planning #planningContainer {
  padding-top: 0;
}

.content.dayplanning,
.week-planning .content.weekplanning {
  background: transparent;
  border: none;
}

.dayplanning-page .content form#search,
.week-planning .content form#search {
  width: calc(100% - 260px);
  position: absolute;
  top: -51px;
  left: 10px;
  right: 0;
}

.week-planning .content .filter {
  top: -33px;
}

.week-planning .content form#search.advanced {
  width: calc(100% - 560px);
}

.content.fix {
  width: calc(100% - 260px);
  position: relative;
  top: -100px;
  background: none;
  border: none;
}

.button.print-page {
  background: #e9e9e9;
  padding: 4px;
  border: 1px solid #929292;
  position: absolute;
  top: 24px;
  right: -30px;
  color: #333;
}

.dayplanning .button.print-page {
  top: -18px !important;
  right: 215px;
}

.toggle-row i.fa-toggle-on,
.toggle-row i.fa-toggle-off {
  font-size: 16px;
  margin-top: 8px;
}

#HourAddForm .users.row input[type='checkbox'] {
  margin-left: 115px;
}

#HourAddForm .users.row label {
  float: none;
  margin-left: 5px;
}

.edit_hour .form-text {
  width: 250px !important;
}

.edit_hour .form-select {
  width: 262px !important;
}

.edit_hour .form-textarea {
  width: 400px;
}

.hour-item {
  padding-top: 25px;
}

.hour-item .title {
  font-size: 14px;
  font-weight: 600;
  margin-left: 25px;
}

.hour-item tr.hidden {
  display: none;
}

.hour-item .no-results-message,
.hour-item .total-message {
  font-size: 12px;
  padding: 5px;
  margin-left: 20px;
}

.hour-item .hours-by-month {
  display: none;
  margin: 20px 0 0 25px;
  font-size: 12px;
}

.hour-item .hours-by-month ul {
  margin-left: 25px;
}

.hour-item .toggle-all-results,
.hour-item .toggle-hours-by-month {
  margin-left: 25px;
  color: #81B32B;
  font-size: 12px;
}

.overzicht tr td a.open-signature {
  color: #337ab7;
}

.floatThead-container {
  background: #f9f9f9;
}

.form-cancel,
.form-submit {
  float: right;
  height: 35px;
  width: auto !important;
  line-height: 35px;
  padding: 0 20px;
  margin: 0 0 0 10px;
  border: 0;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.print_overzicht .form-submit.print-button {
  background: #fb8c1e;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZiOGMxZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjdmMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(left, #fb8c1e 0%, #ff7f00 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #fb8c1e), color-stop(100%, #ff7f00));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #fb8c1e 0%, #ff7f00 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #fb8c1e 0%, #ff7f00 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #fb8c1e 0%, #ff7f00 100%);
  /* IE10+ */
  background: linear-gradient(to right, #fb8c1e 0%, #ff7f00 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fb8c1e', endColorstr='#ff7f00', GradientType=1);
  /* IE6-8 */
  margin-top: 20px;
  margin-right: 40px;
}

.print_overzicht .load {
  color: #7a7a7a;
  float: left;
  margin-top: 20px;
  margin-left: 40px;
}

.project_overzicht .print_view li {
  padding: 10px;
  border-bottom: 1px solid #cecece;
}

.project_overzicht .print_view li:nth-child(1) {
  border-top: 1px solid #cecece;
}

.project_overzicht .print_view {
  width: 50%;
  font-size: 11px;
  list-style-type: none;
  float: left;
  padding-left: 40px;
  padding-right: 20px;
}

#page_title_view {
  margin-bottom: 10px;
  padding: 30px 30px 22px 50px;
  font-size: 24px;
  line-height: 20px;
  font-weight: 600;
  background: 0 0;
}

.rightcolumn #machinelist li {
  font-size: 12px;
  color: #404040;
  /*list-style: none;*/
  cursor: move;
  line-height: 24px;
  border-top: 1px solid #cecece;
}

.rightcolumn #machinelist li div {
  background: #FFF;
  display: inline-block;
  z-index: 999;
  cursor: move;
}

.rightcolumn #machinelist li div.ui-draggable-dragging {
  background: #000;
  color: #FFF;
  padding: 0 5px 0 5px;
  z-index: 9999;
}

#content_wrapper.planning-view {
  padding: 75px 25px 25px 245px;
}

.print-view {
  list-style-type: none;
}

.print-view.left {
  width: 50%;
}

input#ConfigurationDescription,
input#EmployeeCategoryName,
input#EmployeeFunctionName,
input#LeaveTypeName,
input#ProjectCategoryName {
  background: #f5f5f5;
  height: 23px;
  line-height: 23px;
  border: 1px solid #747474;
  color: #434343;
  font-size: 12px;
  font-weight: normal;
  float: left;
}

.overzicht td.type span {
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
}

.read_only_mode {
  display: inline-block;
  font-size: 12px;
  color: red;
}

/* Logbook */
.logbookContainer {
  max-width: 360px;
  margin-top: 30px;
}

#logbookTitle {
  padding: 10px 10px 10px 22px;
  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;
  border-left: 1px solid #cecece;
  background: #f5f5f5;
  position: relative;
}

span.logtitle {
  font-weight: 600;
  font-size: 17px;
}

.logbookContainer .overzicht {
  max-width: 360px;
}

.logbookContainer .overzicht tr.title td,
.logbookContainer .overzicht tr.title th {
  width: 100%;
}

span.logClick {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: -webkit-linear-gradient(left, #fb8c1e 0, #ff7f00 100%);
  background: -o-linear-gradient(left, #fb8c1e 0, #ff7f00 100%);
  background: -ms-linear-gradient(left, #fb8c1e 0, #ff7f00 100%);
  background: linear-gradient(to right, #fb8c1e 0, #ff7f00 100%);
  -webkit-text-fill-color: #FFF;
  color: #FFF;
  padding: 1px 20px;
  font-size: 20px;
  line-height: 40px;
  cursor: pointer;
}

span.logbookLength {
  color: #999;
  font-size: 12px;
  font-style: italic;
  position: absolute;
  padding: 0 5px;
}

.logbookArrayContainer {
  display: none;
  height: 0;
}

tr.logbookHeaderArray {
  text-align: left;
  background-color: #f5f5f5;
  padding: 5px 0;
}

tr.logbookHeaderArray th {
  padding: 5px 5px;
  color: #434343;
  font-weight: 600;
  border-bottom: 1px solid #cecece;
}

.active .logbookArrayContainer {
  display: block;
  height: auto;
  max-height: 182px;
  overflow-y: scroll;
  transition: all .5s ease-out;
  -webkit-transition: all .5s ease-in-out;
  margin-top: 0;
}

/** FTE PLANNING **/

div.fte-content {
  width: 100% !important;
}

.fte-table td, .fte-table th {
  border: 1px solid #ddd;
  padding: 5px;
}

.fte-table {
  border-collapse: collapse;
  width: 100%;
  background: #fff;
  margin: 50px auto 0 auto;
}

.fte-table th {
  border-bottom: 2px solid #ddd;
}

.totals {
  font-weight: bold;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

.totals td:first-child {
  white-space: nowrap;
}

.fte-table-container {
  width: 100%;
  overflow-x: scroll;
}

.fte-date-range {
  text-align: center;
}

.fte-date {
  white-space: nowrap;
}

.fte-tm {
  text-align: center;
}

.fte-table tr:hover {
  background-color: #f5f5f5;
}

td.negative {
  color: #ff0000;
}

.fte-table td.type {
  text-align: left;
  padding-left: 7px;
  padding-right: 31px;
  background: none !important;
}

.fte-table td.type span {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 10px;
}

.fte-filter-button {
  position: absolute;
  top: 60px;
  margin-left: 12px;
  text-decoration: none;
  padding: 7px 12px;
  background: green;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  z-index: 999;
}

.non-planned-employees ul {
  width: calc(100% - 25px);
  margin-left: 25px;
}

.form .selectize-control {
  display: inline-block;
  width: 261px;
  margin-bottom: 5px;
  padding: 0;
}

.form .selectize-input {
  height: 25px;
  min-height: 25px;
  padding: 2px 8px;
  background: #f5f5f5;
  border: 1px solid #747474;
  font-size: 12px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.form .selectize-control.single .selectize-input:after {
  right: 8px;
}

.form .selectize-control.multi .selectize-input {
  height: 75px;
}

.form .selectize-dropdown {
  padding: 0;
}

.form .selectize-dropdown-content {
  background: #f9f9f9;
  border: 1px solid #747474;
}

.form .selectize-control.multi .selectize-input .item {
  background: #bfbfbf;
  font-size: 12px;
}

.form .selectize-dropdown .option {
  font-size: 12px;
}

.form .selectize-dropdown .option:hover {
  background: #e5e5e5;
}

.selectize-control.multi .selectize-input.has-items {
  overflow-y: scroll;
}

.employee-row-disabled {
  background: #dadada;
}

form#PlanningEmployeeForm {
  margin-top: 20px;
}

#map {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.modal.remove-plannings-confirmation .button.cancel {
  background: transparent;
  color: #7a7a7a;
}

.sub-view {
  width: 75%;
  /*padding-left: 50px;*/
}

.sub-view .sub-title {
  padding: 30px 30px 22px 50px;
  font-size: 24px;
  line-height: 20px;
  font-weight: 600;
  background: 0 0;
}

.overzicht.default td.actions a.button {
  float: left;
  width: 25px;
  font-size: 13px;
  text-align: center;
  text-decoration: none;
  color: #fff;
}

.overzicht.default tr {
  color: #848484;
}

.overzicht.default tr.odd {
  background: #F9FAFB;
}

.overzicht.default td {
  border-bottom: 1px solid #cecece;
  height: 18px;
  line-height: 18px;
  padding: 5px;
}

.ui-widget-header .ui-dialog-titlebar-close {
  background: #848485;
  border: 0;
}

.employee-nav-link {
  color: #333;
  text-decoration: none;
}

.employee-nav-link.nav-right {
  display: block;
  text-align: right;
}

.employee-nav-link:hover {
  text-decoration: underline;
}

.photos .row {
  padding: 5px;
  margin-bottom: 10px;
}

.photos .row img {
  max-height: 80vh;
  max-width: 80vw;
}

.add-photos-wrapper {
  margin-top: 15px;
}

/**
 * PA van Rooyen Theming
 */
body.pavanrooyen .filtered {
  color: #FFFFFF;
}

body.pavanrooyen .filtered a {
  background: #008A8D;
}

body.pavanrooyen .filtered {
  background: #00999C;
  color: #FFFFFF;
}

body.pavanrooyen .filtered a {
  background: #008A8D;
}

body.pavanrooyen .form .form-submit,
body.pavanrooyen .form .form-submit-left,
body.pavanrooyen .form .form-submit-right,
body.pavanrooyen .btn {
  background: #00999C;
}

body.pavanrooyen .form .form-submit:hover,
body.pavanrooyen .form .form-submit-left:hover,
body.pavanrooyen .form .form-submit-right:hover,
body.pavanrooyen .btn:hover {
  background: #008A8D;
}

body.pavanrooyen #pagination span {
  background: #00999C;
}

body.pavanrooyen .form .form-submit:hover,
body.pavanrooyen .form .form-submit-left:hover,
body.pavanrooyen .form .form-submit-right:hover,
body.pavanrooyen .btn:hover {
  background: #008A8D;
}

body.pavanrooyen #pagination span.current,
body.pavanrooyen #pagination a:hover {
  background: #008A8D;
}

body.pavanrooyen #conflicts .button,
body.pavanrooyen .modal .button,
body.pavanrooyen .button.primary {
  background: #00999C;
}

body.pavanrooyen .hour-item .toggle-all-results,
body.pavanrooyen .hour-item .toggle-hours-by-month {
  color: #00999C;
}

body.pavanrooyen .fte-filter-button {
  background: #008A8D;
}

/**
 * Coverplan
 */
body.coverplan .filtered {
  color: #666666;
}

body.coverplan .filtered a {
  background: #666666;
}

body.coverplan .filtered {
  background: #666666;
  color: #FFFFFF;
}

body.coverplan .filtered a {
  background: #666666;
}

body.coverplan .form .form-submit,
body.coverplan .form .form-submit-left,
body.coverplan .form .form-submit-right,
body.coverplan .btn {
  background: #666666;
}

body.coverplan .form .form-submit:hover,
body.coverplan .form .form-submit-left:hover,
body.coverplan .form .form-submit-right:hover,
body.coverplan .btn:hover {
  background: #666666;
}

body.coverplan #pagination span {
  background: #666666;
}

body.coverplan .form .form-submit:hover,
body.coverplan .form .form-submit-left:hover,
body.coverplan .form .form-submit-right:hover,
body.coverplan .btn:hover {
  background: #666666;
}

body.coverplan #pagination span.current,
body.coverplan #pagination a:hover {
  background: #666666;
}

body.coverplan #conflicts .button,
body.coverplan .modal .button,
body.coverplan .button.primary {
  background: #666666;
}

body.coverplan .hour-item .toggle-all-results,
body.coverplan .hour-item .toggle-hours-by-month {
  color: #666666;
}

body.coverplan .fte-filter-button {
  background: #666666;
}

/**
 * Van Leeuwen Theming
 */
body.vanleeuwen .filtered {
  color: #FFFFFF;
}

body.vanleeuwen .filtered a {
  background: #155895;
}

body.vanleeuwen .filtered {
  background: #155895;
  color: #FFFFFF;
}

body.vanleeuwen .filtered a {
  background: #155895;
}

body.vanleeuwen .form .form-submit,
body.vanleeuwen .form .form-submit-left,
body.vanleeuwen .form .form-submit-right,
body.vanleeuwen .btn {
  background: #155895;
}

body.vanleeuwen .form .form-submit:hover,
body.vanleeuwen .form .form-submit-left:hover,
body.vanleeuwen .form .form-submit-right:hover,
body.vanleeuwen .btn:hover {
  background: #155895;
}

body.vanleeuwen #pagination span {
  background: #155895;
}

body.vanleeuwen .form .form-submit:hover,
body.vanleeuwen .form .form-submit-left:hover,
body.vanleeuwen .form .form-submit-right:hover,
body.vanleeuwen .btn:hover {
  background: #155895;
}

body.vanleeuwen #pagination span.current,
body.vanleeuwen #pagination a:hover {
  background: #155895;
}

body.vanleeuwen #conflicts .button,
body.vanleeuwen .modal .button,
body.vanleeuwen .button.primary {
  background: #155895;
}

body.vanleeuwen .hour-item .toggle-all-results,
body.vanleeuwen .hour-item .toggle-hours-by-month {
  color: #155895;
}

body.vanleeuwen .fte-filter-button {
  background: #155895;
}

/**
 * PP Group Theming
 */
body.ppgroup .filtered {
  color: #FFFFFF;
}

body.ppgroup .filtered a {
  background: #00A8DE;
}

body.ppgroup .filtered {
  background: #00A8DE;
  color: #FFFFFF;
}

body.ppgroup .filtered a {
  background: #00A8DE;
}

body.ppgroup .form .form-submit,
body.ppgroup .form .form-submit-left,
body.ppgroup .form .form-submit-right,
body.ppgroup .btn {
  background: #00A8DE;
}

body.ppgroup .form .form-submit:hover,
body.ppgroup .form .form-submit-left:hover,
body.ppgroup .form .form-submit-right:hover,
body.ppgroup .btn:hover {
  background: #00A8DE;
}

body.ppgroup #pagination span {
  background: #00A8DE;
}

body.ppgroup .form .form-submit:hover,
body.ppgroup .form .form-submit-left:hover,
body.ppgroup .form .form-submit-right:hover,
body.ppgroup .btn:hover {
  background: #00A8DE;
}

body.ppgroup #pagination span.current,
body.ppgroup #pagination a:hover {
  background: #00A8DE;
}

body.ppgroup #conflicts .button,
body.ppgroup .modal .button,
body.ppgroup .button.primary {
  background: #00A8DE;
}

body.ppgroup .hour-item .toggle-all-results,
body.ppgroup .hour-item .toggle-hours-by-month {
  color: #00A8DE;
}

body.ppgroup .fte-filter-button {
  background: #00A8DE;
}

/**
 * Total Creation Theming
 */
body.total-creation .filtered {
  color: #FFFFFF;
}

body.total-creation .filtered a {
  background: #CCD724;
}

body.total-creation .filtered {
  background: #CCD724;
  color: #FFFFFF;
}

body.total-creation .filtered a {
  background: #CCD724;
}

body.total-creation .form .form-submit,
body.total-creation .form .form-submit-left,
body.total-creation .form .form-submit-right,
body.total-creation .btn {
  background: #CCD724;
}

body.total-creation .form .form-submit:hover,
body.total-creation .form .form-submit-left:hover,
body.total-creation .form .form-submit-right:hover,
body.total-creation .btn:hover {
  background: #CCD724;
}

body.total-creation #pagination span {
  background: #CCD724;
}

body.total-creation .form .form-submit:hover,
body.total-creation .form .form-submit-left:hover,
body.total-creation .form .form-submit-right:hover,
body.total-creation .btn:hover {
  background: #CCD724;
}

body.total-creation #pagination span.current,
body.total-creation #pagination a:hover {
  background: #CCD724;
}

body.total-creation #conflicts .button,
body.total-creation .modal .button,
body.total-creation .button.primary {
  background: #CCD724;
}

body.total-creation .hour-item .toggle-all-results,
body.total-creation .hour-item .toggle-hours-by-month {
  color: #CCD724;
}

body.total-creation .fte-filter-button {
  background: #CCD724;
}

/**
 * Campina Theming
 */
body.campina .filtered {
  color: #FFFFFF;
}

body.campina .filtered a {
  background: #0093DF;
}

body.campina .filtered {
  background: #0093DF;
  color: #FFFFFF;
}

body.campina .filtered a {
  background: #0093DF;
}

body.campina .form .form-submit,
body.campina .form .form-submit-left,
body.campina .form .form-submit-right,
body.campina .btn {
  background: #0093DF;
}

body.campina .form .form-submit:hover,
body.campina .form .form-submit-left:hover,
body.campina .form .form-submit-right:hover,
body.campina .btn:hover {
  background: #0093DF;
}

body.campina #pagination span {
  background: #0093DF;
}

body.campina .form .form-submit:hover,
body.campina .form .form-submit-left:hover,
body.campina .form .form-submit-right:hover,
body.campina .btn:hover {
  background: #0093DF;
}

body.campina #pagination span.current,
body.campina #pagination a:hover {
  background: #0093DF;
}

body.campina #conflicts .button,
body.campina .modal .button,
body.campina .button.primary {
  background: #0093DF;
}

body.campina .hour-item .toggle-all-results,
body.campina .hour-item .toggle-hours-by-month {
  color: #0093DF;
}

body.campina .fte-filter-button {
  background: #0093DF;
}

/**
 * Tuinonderhoud Emmen
 */
body.tuinonderhoud-emmen .filtered {
  color: #FFFFFF;
}

body.tuinonderhoud-emmen .filtered a {
  background: #186C2C;
}

body.tuinonderhoud-emmen .filtered {
  background: #186C2C;
  color: #FFFFFF;
}

body.tuinonderhoud-emmen .filtered a {
  background: #186C2C;
}

body.tuinonderhoud-emmen .form .form-submit,
body.tuinonderhoud-emmen .form .form-submit-left,
body.tuinonderhoud-emmen .form .form-submit-right,
body.tuinonderhoud-emmen .btn {
  background: #186C2C;
}

body.tuinonderhoud-emmen .form .form-submit:hover,
body.tuinonderhoud-emmen .form .form-submit-left:hover,
body.tuinonderhoud-emmen .form .form-submit-right:hover,
body.tuinonderhoud-emmen .btn:hover {
  background: #186C2C;
}

body.tuinonderhoud-emmen #pagination span {
  background: #186C2C;
}

body.tuinonderhoud-emmen .form .form-submit:hover,
body.tuinonderhoud-emmen .form .form-submit-left:hover,
body.tuinonderhoud-emmen .form .form-submit-right:hover,
body.tuinonderhoud-emmen .btn:hover {
  background: #186C2C;
}

body.tuinonderhoud-emmen #pagination span.current,
body.tuinonderhoud-emmen #pagination a:hover {
  background: #186C2C;
}

body.tuinonderhoud-emmen #conflicts .button,
body.tuinonderhoud-emmen .modal .button,
body.tuinonderhoud-emmen .button.primary {
  background: #186C2C;
}

body.tuinonderhoud-emmen .hour-item .toggle-all-results,
body.tuinonderhoud-emmen .hour-item .toggle-hours-by-month {
  color: #186C2C;
}

body.tuinonderhoud-emmen .fte-filter-button {
  background: #186C2C;
}

/* Activities and materials modal */
.modal.activities > div,
.modal.materials > div {
  max-height: 80vh;
  color: #333;
}

.modal.activities .title,
.modal.materials .title {
  margin-bottom: 20px;
}

.modal.activities table,
.modal.materials table {
  width: initial;
  min-width: 40vw;
}

.modal.activities .add-activity,
.modal.materials .add-material {
  margin: 20px 0;
  border-bottom: 1px solid #dadada;
  position: relative;
}

.modal.activities .add-activity .selectize-control,
.modal.materials .add-material .selectize-control {
  min-width: 400px;
  text-align: left;
  border: none;
  line-height: 12px;
}

.modal.activities .add-activity input[type=text],
.modal.activities .add-activity input[type=number],
.modal.materials .add-material input[type=text],
.modal.materials .add-material input[type=number] {
  min-width: 388px;
}

.modal.activities .add-activity input[type=submit],
.modal.materials .add-material input[type=submit] {
  font-size: 16px;
  line-height: 30px;
  position: absolute;
  right: 140px;
  bottom: -55px;
}

.modal.activities table td,
.modal.materials table td {
  line-height: initial;
  padding: 0;
}

.modal.activities table td .button,
.modal.materials table td .button {
  border-radius: 4px;
  padding: 1px 9px;
  font-size: 13px;
}

.modal.activities .cancel,
.modal.materials .cancel {
  float: right;
  padding: 0 20px;
}
